import React, { useState, useEffect, useRef } from 'react';
import Tabbar from '@/components/Tabbar/Tabbar';
import { Dialog, Button, Toast } from 'antd-mobile';
import { scanType } from '@/constant/scanType';
import { useDispatch, useSelector } from 'react-redux';
import { authCheck, getUserInfo, checkUnpayOrder, checkoutDeviceStatus, checkSign, checkUserStatus, appendFrontendLog } from '@/utils/common';
import { useNavigate } from 'react-router-dom';
import './Code.less';
import http from '@/http/http';
import platform from '@/platform'

import { setLocal, getLocal } from '@/utils/storage';

export default function Code() {

  let codeList = [
    {
      value: ''
    },
    {
      value: ''
    },
    {
      value: ''
    },
    {
      value: ''
    }
  ];
  let [codeValue, setCodeValue] = useState(''); // code input输入值
  let [inputCode, setInputCode] = useState(codeList); // code显示
  let [isFocus, setInputFocus] = useState(false); // input focus控制
  let [isConfirmDisabled, setConfirmDisabled] = useState(false);
  const inputRef = useRef(null); // code input DOM
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const platformObject = useSelector((state: any) => state.currentPlatform.platform)
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform)
  // store中用户信息
  const globalUserInfo = useSelector((state: any) => {
    return state.userInfo.userInfo;
  })
  const userInfoStorage = getLocal('userInfo')
  let userInfo = Object.assign({}, globalUserInfo, userInfoStorage);
  /**
   * @desc 修改显示框中的柜码数据
   * @param {String} value 输入框的值
  **/
  const updateCodeList = (value: string) => {
    if (value.length > codeList.length)
      return;
    const arr = value.split('');
    for (let i = 0; i < arr.length; i++) {
      codeList[i].value = arr[i]
    }
    setInputCode(codeList)
  }

  // code 输入中
  const codeInputHandle = (e: any) => {
    let value = e.target.value;
    let numberReg = /\D/g;
    value = value.replace(numberReg, '');
    if(value.length > 4) {
      value = value.slice(0, 4);
    }
    setCodeValue(value)
    updateCodeList(value)
  }

  // code focus方法
  const codeInputFocus = () => {
    setInputFocus(true)
  }

  // code blur方法
  const codeInputBlur = () => {
    setInputFocus(false)
  }

  /**
   * @desc 点击让输入框获取焦点
  **/
  const codeInputStart = () => {
    (inputRef as any).current.focus();
  }

  /**
   * @desc 根据code获取设备id
  **/
  const getDeviceIdByCode = () => {
    return new Promise((resolve, reject) => {
      http.get({
        url: `/api/cuser/V1/Customer/GetDeviceSnByShortCode/${codeValue}`,
        data: {}
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 是否有未支付
  **/
  const OrderStatus = async (deviceId: string) => {
    let { code, data } = await checkUnpayOrder(platformObject.memberSource) as any;
    let alipayModalShow = false;
    let wechatModalShow = false;
    if(code === 1) {
      if(data && data.length) { // 有未支付
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        let item = data[0];
        if(item.source === scanType.WECHAT) { // 微信订单
          if(!wechatModalShow) {
            wechatModalShow = true;
            Dialog.confirm({
              title: '温馨提示',
              content: <section>您有未支付的微信小程序订单,为避免影响您后续购物,请前往微信小程序完成支付。如有疑问请联系客服或拨打电话 <a href="tel:029-86627864" onClick={() => {
                Dialog.clear()
              }}>029-86627864</a>。</section>,
              closeOnMaskClick: false,
              confirmText: '确认',
              cancelText: '取消',
              onConfirm() {
                console.log('Confirmed')
              },
              onCancel() {

              }
            })
          }
        } else { // 其他订单
          if(!alipayModalShow) {
            alipayModalShow = true;
            Dialog.confirm({
              title: '温馨提示',
              content: <section>您有未支付的订单,为避免影响您后续购物,请完成支付。如有疑问请联系客服或拨打电话 <a href="tel:029-86627864" onClick={() => {
                Dialog.clear()
              }}>029-86627864</a>。</section>,
              closeOnMaskClick: false,
              confirmText: '去支付',
              cancelText: '取消',
              onConfirm() {
                navigate('/orderDetail', { state: { orderSn: item.orderNo }})
              },
              onCancel() {

              },
            })
          }
        }
        setConfirmDisabled(false);
      } else { // 没有未支付
        const { code } = await checkUserStatus() as { code: number };
        if(code === 1) {
          if(deviceId) {
            DeviceEnable(deviceId)
          } else {
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            Toast.show({
              icon: 'fail',
              content: '请扫正确的设备码',
            })
            setConfirmDisabled(false);
          }
        } else if(code !== 1 && code !== undefined) {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          setConfirmDisabled(false);
          Dialog.confirm({
            title: '提示',
            content: '网络异常，请稍后再试',
            closeOnMaskClick: false,
            confirmText: '确认',
            cancelText: '取消',
            onConfirm() {
              console.log('Confirmed')
            },
            onCancel() {

            }
          })
        }
      }
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      setConfirmDisabled(false);
    }
    appendFrontendLog({
      label: 'Code-WaitPayOrder',
      content: JSON.stringify({code, data})
    })
  }

  /**
   * @desc 设备状态解析
  **/
  const DeviceEnable = async (deviceId: string) => {
    dispatch({
      type: 'SET_DEVICEID',
      value: deviceId
    })
    setLocal({ key: 'globalDeviceId', value: deviceId })
    const { code, data, msg } = await checkoutDeviceStatus(deviceId) as any;
    if(code === 0) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: '设备不可用',
      })
      setConfirmDisabled(false);
    } else if(code === 1) {
      resolveProtocol()
      // navigate('/purchas')
    } else if(code === -101) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      navigate('/userLogin')
      setConfirmDisabled(false);
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: msg || data.msg || data.message,
      })
      setConfirmDisabled(false);
    }
    appendFrontendLog({
      label: 'Code-DeviceEnable',
      content: JSON.stringify({code, data, msg})
    })
  }

  /**
   * @desc 检查是否签约
  */
   const resolveProtocol = async () => {
    const { code } = await checkSign(currentPlatform) as any;
    if(code === 1) { // 签约成功，开始调用开门
      setConfirmDisabled(false);
      navigate('/purchas')
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      setConfirmDisabled(false);
      let path = '';
      switch(currentPlatform) {
        case 'alipay':
          path = '/protocol';
          break;
        case 'cmb':
          const { code: signCode, data: signUrl, message: signMsg } = await platform.cmb.cardSign(userInfo) as { code: number, data: string, message: string }
          if(signCode === 1 && signUrl) {
            window.location.href = signUrl;
          } else {
            Toast.show({
              icon: 'fail',
              content: signMsg || '授权失败',
            })
          }
          break;
        default:
          break;
      }
      navigate(path, { state: 1 })
    }
  }

  /**
   * @desc 确认开门
  **/
  const ConfirmCodeOpen = async () => {
    if(isConfirmDisabled) return;
    setConfirmDisabled(true);
    if(!codeValue) {
      Toast.show({
        icon: 'fail',
        content: '请输入柜码',
      })
      setConfirmDisabled(false);
    } else if(codeValue && codeValue.length < 4) {
      Toast.show({
        icon: 'fail',
        content: '柜码输入错误',
      })
      setConfirmDisabled(false);
      appendFrontendLog({
        label: 'user-input-Code',
        content: JSON.stringify(codeValue)
      })
    } else {
      dispatch({
        type: 'SET_SHOW_LOADING'
      })
      const userAuth = await authCheck();
      if(userAuth) {
        let { code, data, msg } = await getDeviceIdByCode() as any;
        if(code === 1) {
          OrderStatus(data)
        } else if(code === -100) {
          if(currentPlatform === 'alipay') {
            const { authCode } = await platform.alipay.sign() as { authCode?: string, error?: number };
            if(authCode) {
              const { code: userCode, data: userData } = await getUserInfo(authCode) as any;
              if(userCode === 1) {
                userData.token && setLocal({ key: 'token', value: userData.token })
                setLocal({ key: 'userInfo', value: userData.memberInfo })
                if(userData.memberInfo != null && userData.memberInfo.mobile !== '') {
                  dispatch({
                    type: 'SET_USERINFO',
                    value: userData.memberInfo
                  })
                  let { code, data: deviceData } = await getDeviceIdByCode() as any;
                  if(code === 1) {
                    OrderStatus(deviceData)
                  } else {
                    dispatch({
                      type: 'SET_HIDE_LOADING'
                    })
                    Toast.show({
                      icon: 'fail',
                      content: '柜码输入错误',
                    })
                    setConfirmDisabled(false);
                  }
                  appendFrontendLog({
                    label: 'getDeviceIdByCode',
                    content: JSON.stringify({code, data: deviceData})
                  })
                } else {
                  dispatch({
                    type: 'SET_HIDE_LOADING'
                  })
                  setConfirmDisabled(false);
                  navigate('/userLogin');
                }
              }
            }
          } else {
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            setConfirmDisabled(false);
            navigate('/userLogin', { replace: true })
          }
        } else {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          Toast.show({
            icon: 'fail',
            content: msg || '柜码输入错误',
          })
          setConfirmDisabled(false);
          appendFrontendLog({
            label: 'getDeviceIdByCode',
            content: JSON.stringify({code, data, msg})
          })
        }
      } else {
        if(currentPlatform === 'alipay') {
          const { authCode } = await platform.alipay.sign() as { authCode: string };
          if(authCode) {
            const { code, data } = await getUserInfo(authCode) as any;
            if(code === 1) {
              data.token && setLocal({ key: 'token', value: data.token })
              setLocal({ key: 'userInfo', value: data.memberInfo })
              if(data.memberInfo != null && data.memberInfo.mobile !== '') {
                dispatch({
                  type: 'SET_USERINFO',
                  value: data.memberInfo
                })
                let { code, data: deviceData } = await getDeviceIdByCode() as any;
                if(code === 1) {
                  OrderStatus(deviceData)
                } else {
                  dispatch({
                    type: 'SET_HIDE_LOADING'
                  })
                  Toast.show({
                    icon: 'fail',
                    content: '柜码输入错误',
                  })
                  setConfirmDisabled(false);
                }
                appendFrontendLog({
                  label: 'getDeviceIdByCode',
                  content: JSON.stringify({code, data: deviceData})
                })
              } else {
                dispatch({
                  type: 'SET_HIDE_LOADING'
                })
                setConfirmDisabled(false);
                navigate('/userLogin');
              }
            } else {
              dispatch({
                type: 'SET_HIDE_LOADING'
              })
              setConfirmDisabled(false);
            }
            await appendFrontendLog({
              label: 'getUserInfo-Code',
              content: JSON.stringify(data)
            })
          } else {
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            setConfirmDisabled(false);
          }
        } else {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          setConfirmDisabled(false);
          navigate('/userLogin', { replace: true })
        }
      }
    }
  }

  useEffect(() => {
    platform[currentPlatform as keyof typeof platform].setTitle('柜码开门');
  }, [])

  return (
    <section className="Code">
      <section className="swiper" style={{ display: isFocus ? 'none' : 'block' }}></section>
      <section className="banner"></section>
      <section className="Code-box"
        style={{ marginTop: isFocus ? '-10vh' : '-9vh' }}
        onClick={codeInputStart}>
        <p className="title">请输入柜贴中间的四位数字</p>
        <input type="tel"
          ref={inputRef}
          value={codeValue}
          className="code-input"
          onChange={(e: any) => codeInputHandle(e)}
          onFocus={codeInputFocus}
          onBlur={codeInputBlur} />
        <ul className="code-item">
          {
            inputCode.map((item: any, index: number) => {
              return (
                <li key={index}
                  className={
                    index === codeValue.length && isFocus ? 'focus' : 'blur'
                  }>
                  {
                    item.value
                  }
                </li>
              )
            })
          } </ul>
      </section>
      <section className="confirm-btn">
        <Button block size="large" onClick={ConfirmCodeOpen}>确认开门</Button>
      </section>
      <footer style={{ display: isFocus ? 'none' : 'block' }}>
        <Tabbar />
      </footer>
    </section>
  )
}
