import { Navigate } from 'react-router-dom';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkUserRegistry, getTokenAndUserInfo } from '@/utils/common';
import { setLocal } from '@/utils/storage';

export function Auth({ children }: { children: JSX.Element }) {
  let currentPlatform = useSelector((state: any) => state.currentPlatform.platform);
  const dispatch = useDispatch();
  const token = useSelector((state: any) => {
    return state.globalToken.token;
  })
  if(token) {
    return <>{children}</>
  } else {
    if(currentPlatform.platform === 'abc') {
      let abcUserId = currentPlatform.AbcUserId ?? currentPlatform.abcUserId;
      checkUserRegistry(abcUserId).then((res: any) => {
        if(res.code === 1 && res.data) { // 新用户
          return <Navigate to="/userLogin" replace></Navigate>
        }
        getTokenAndUserInfo(abcUserId).then((result: any) => {
          setLocal({ key: 'token', value: result.data.token });
          setLocal({ key: 'userInfo', value: result.data.memberInfo });
          dispatch({
            type: 'SET_TOKEN',
            value: result.data.token
          });
          dispatch({
            type: 'SET_USERINFO',
            value: result.data.memberInfo
          });
          return <>{children}</>
        })
      })
    }
    return <Navigate to="/userLogin" replace></Navigate>
  }
}
