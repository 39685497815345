let activeKey = {
  name: 'home'
}

export default function activeTabKey(state = activeKey, action: any) {
  switch (action.type) {
    case 'SET_ACTIVE':
      return { name: action.value};
    default:
      return state;
  }
}