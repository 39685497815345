import React, { useEffect } from 'react';
import Alipay from '@/platform/alipay';
import { useDispatch } from 'react-redux';
import './about.less';

export default function About() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_NAVTITLE',
      value: '关于我们'
    })
    Alipay.setTitle('关于我们')
  }, [])

  return (
    <section className="About">
      <header></header>
      <section className="info">
        <aside>微信小程序</aside>
        <aside>岩心智能</aside>
      </section>
      <section className="info">
        <aside>联系电话</aside>
        <aside>
          <a href="tel:029-86627864">029-86627864</a>
        </aside>
      </section>
      <section className="company">西安岩心智能科技有限公司</section>
    </section>
  )
}