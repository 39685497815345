import React, { useEffect } from 'react';
import { Collapse } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import platform from '@/platform';
import './question.less';

export default function Question() {
  let currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  const dispatch = useDispatch();

  useEffect(() => {
    currentPlatform && platform[currentPlatform as keyof typeof platform].setTitle('常见问题');
    dispatch({
      type: 'SET_NAVTITLE',
      value: '常见问题'
    })
  }, [])

  return (
    <section className="Question">
      <Collapse accordion>
        <Collapse.Panel key="1" title="1.扣款问题">
          <p>Q:扣款延迟怎么办?</p>
          <p>
            A:因为手机信号质量、基站信号质量和其他相关因素会出现延迟扣款问题，如三分钟之后还未扣款，烦请致电客服沟通解决。
          </p>
          <p>Q:多扣少扣怎么办?</p>
          <p>A:烦请致电客服沟通解决。</p>
        </Collapse.Panel>
        <Collapse.Panel key="2" title="2.退款问题">
          <p>
            A:智能货柜内的所有商品，一经售出，订单完结后不接受退换货处理，特殊情况烦请致电客服沟通解决。
          </p>
        </Collapse.Panel>
        <Collapse.Panel key="3" title="3.退换问题">
          <p>
            A:客服操作退款后,退款会沿原支付路径退回，24小时未收到退款烦请致电客服沟通解决。
          </p>
        </Collapse.Panel>
      </Collapse>
      <section className="info">
        <aside>服务时间</aside>
        <aside>周一至周五 9:00到18:00</aside>
      </section>
      <section className="info">
        <aside>联系客服</aside>
        <aside>
          <a href="tel:029-86627864">029-86627864</a>
        </aside>
      </section>
    </section>
  )
}