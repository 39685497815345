import React from 'react';
import { LeftOutline, CloseCircleOutline } from 'antd-mobile-icons';
import { useSelector } from 'react-redux';
import platform from '@/platform';
import { useNavigate, useLocation } from 'react-router-dom';
import './Nav.less';

export default function Nav() {

  const location = useLocation();
  const navTitle = useSelector((state: any) => state.currentNavTitle.name)
  const navigate = useNavigate();
  let currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  const routerBack = () => {
    if(['/', '/home', '/user'].includes(location.pathname)) {
      platform[currentPlatform as keyof typeof platform].closeWindow();
    } else {
      if(window.history.length > 1) {
        navigate(-1)
      } else {
        exitApp()
      }
    }
  }

  const exitApp = () => {
    platform[currentPlatform as keyof typeof platform].closeWindow();
  }

  return (
    <nav className="nav-title">
      <LeftOutline fontSize={22} onClick={routerBack} className="back-icon" />
      <span className="title-text">{ navTitle }</span>
      <CloseCircleOutline fontSize={20} onClick={exitApp} className="close-icon" />
    </nav>
  )
}