import React from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import App from '@/views/App/App';
import Home from '@/views/Home/Home';
import Code from '@/views/Code/Code';
import User from '@/views/User/User';
import Login from '@/views/userLogin/userLogin';
import Protocol from '@/views/protocol/protocol';
import OrderList from '@/views/orderList/orderList';
import SupplyOrderList from '@/views/supplyOrderList/supplyOrderList';
import OrderDetail from '@/views/orderDetail/orderDetail';
import Question from '@/views/question/question';
import About from '@/views/about/about';
import Purchas from '@/views/Purchas/Purchas';

import AbcSign from '@/views/abc/sign/sign';
import AbcAgreement from '@/views/abc/agreement/agreement';
import AbcHappy from '@/views/abc/happy/happy';

import PsbcSign from '@/views/psbc/sign/sign';
import PsbcAgreement from '@/views/psbc/agreement/agreement';
import PsbcAgreementCard from '@/views/psbc/agreementCard/agreementCard';

import Upgrade from '@/views/upgrade/upgrade';
import { Auth } from '@/router/auth';

class Router extends React.PureComponent{
  render() {
    return (
      <HashRouter>
        <App>
          <Routes>
            <Route path='/' element={ <Home/> } />
            <Route path='/home' element={ <Home/>} />
            <Route path='/code' element={ <Code/> } />
            <Route path='/user' element={ <User/> } />
            <Route path='/userLogin' element={ <Login/> } />
            <Route path='/protocol' element={ <Auth><Protocol/></Auth> } />
            <Route path='/orderList' element={ <Auth><OrderList/></Auth> } />
            <Route path='/supplyOrderList' element={ <Auth><SupplyOrderList/></Auth> } />
            <Route path='/orderDetail' element={ <Auth><OrderDetail/></Auth> } />
            <Route path='/question' element={ <Question/> } />
            <Route path='/aboutUs' element={ <About/>} />
            <Route path='/purchas' element={ <Auth><Purchas/></Auth> } />
            <Route path="/abc/sign" element={ <Auth><AbcSign/></Auth>}></Route>
            <Route path="/abc/agreement" element={ <Auth><AbcAgreement/></Auth>}></Route>
            <Route path="/abc/happy" element={ <AbcHappy/>}></Route>
            <Route path="/psbc/sign" element={ <Auth><PsbcSign/></Auth>}></Route>
            <Route path="/psbc/agreement" element={ <Auth><PsbcAgreement/></Auth>}></Route>
            <Route path="/psbc/agreementcard" element={ <Auth><PsbcAgreementCard/></Auth>}></Route>
            <Route path="/upgrade" element={ <Upgrade/>}></Route>
          </Routes>
        </App>
      </HashRouter>
    )
  }
}

export default Router;