import React, { useEffect } from 'react';
import { Button, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Alipay from '@/platform/alipay';
import { useDispatch } from 'react-redux';
import http from '@/http/http';
import './protocol.less';

export default function Protocol() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goHome = () => {
    if(window.history.length > 1) {
      navigate(-1)
    } else {
      navigate('/home', { replace: true })
    }
  }

  const confirmProtocol = () => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    http.post({
      url: '/api/cuser/V1/Member/InitiateSigning',
      data: {}
    }).then(res => {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      const { code, data, message = '' } = res;
      if(code === 1) {
        window.location.href = data.body; //跳转到支付宝签约
        navigate('/home', { replace: true })
      } else {
        Toast.show({
          icon: 'fail',
          content: message || data.message || data.msg,
        })
      }
    }).catch(error => {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: '授权失败'
      })
    })
  }

  useEffect(() => {
    Alipay.setTitle('用户服务协议');
  }, [])
  return(
    <section className="Protocol">
      <p className="title">用户服务协议</p>
      <ol>
        <li>本产品需要用户授权开通支付宝免密代扣功能，购物后自动结算。</li>
        <li>本产品将获取用户手机号，并在必要时联系用户。</li>
        <li>本售货柜为无人值守售货柜，购买商品需要用户自助完成。</li>
        <li>本售货柜购买商品时只允许一个(个人/团体)客户。</li>
        <li>本售货柜全程视频监控。</li>
        <li>在本售货柜购物时，关门代表购物完成，如有问题可随时致电客服热线。</li>
      </ol>
      <section className="ctrl-btn">
        <Button block className="home-btn" size="large" onClick={goHome}>返回首页</Button>
        <Button block className="protocol-btn" size="large" onClick={confirmProtocol}>开通免密支付</Button>
      </section>
    </section>
  )
}