import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './agreementCard.less';

export default function Agreement() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_NAVTITLE',
      value: '服务协议'
    })
  }, [])

	return(
		<div className="page-psbcAgreement">
    <h3>中国邮政储蓄银行借记卡快捷支付业务线上服务协议</h3>

    <p >请客户认真阅读本协议全文，特别是以黑体字加粗标注的内容。如有疑义，请及时拨打中国邮政储蓄银行客服咨询/投诉电话95580。</p>
    <p className="blod">1、中国邮政储蓄银行借记卡智能货柜快捷支付业务是中国邮政储蓄银行（以下简称"邮储银行"）和西安岩心智能科技有限公司（以下简称"智能货柜"）联合为邮储银行借记卡客户（以下简称"客户"）提供的一项通过无人货柜进行便捷支付的业务。凡是持有邮储银行借记卡且同时为智能货柜用户的客户，均可申请该业务。</p>
    <p className="blod">2、客户通过智能货柜无人货柜点击确认选择在线签订本服务协议，开通快捷支付业务功能并承担由此产生的相关法律责任。</p>
    <p className="blod">3、客户同意邮储银行以智能货柜传送至邮储银行的户名、卡号、身份证号、手机号码和手机短信验证码作为确认客户身份、为客户开通快捷支付业务的唯一依据。基于提高交易安全的目的，邮储银行有权变更上述确认身份要素。客户应保证业务开通时在邮储银行预留的手机号码为本人手机号码，否则由此导致的客户损失，邮储银行不承担赔偿责任。客户须妥善保管本人邮储银行借记卡信息、身份信息、手机短信验证码、安全认证工具等敏感信息，不得将敏感信息向他人透露。因客户个人信息、银行卡片信息、手机等保管不善产生的风险及损失由客户本人承担。</p>
    <p className="blod">4、客户同意并授权邮储银行在校验客户手机短信验证码通过后，按照智能货柜的交易指令从客户指定的借记卡账户中支付相应款项，客户对此无异议。</p>
    <p className="blod">5、邮储银行不对智能货柜提交的扣款交易指令进行实质性审核，不负责审核智能货柜提交扣款指令的真实性、合法性、准确性。</p>
    <p className="blod">6、为确保客户网上支付资金安全，邮储银行有权设置或修改支付限额。客户在支付时需同时受邮储银行和智能货柜设置的支付限额的约束。如客户实际支付金额大于规定的支付限额，邮储银行将拒绝执行该笔交易。</p>
    <p className="blod">7、客户承诺在指定的银行付款账户中保留足够的余额，并保持账户处于正常状态。因付款账户余额不足、账户被冻结、销户等原因而导致的无法成功支付费用及所造成的纠纷和违约责任、损失等法律后果客户自行承担。</p>
    <p className="blod">8、邮储银行仅提供通过智能货柜发生的支付结算服务。客户因通过商户购买商品而产生的一切关于商品或服务质量、商品交付的争议，由客户与商品/服务的实际销售商协商解决。</p>
    <p className="blod">9、客户不得利用邮储银行快捷支付业务进行套现、虚假交易、洗钱等行为，且有义务配合邮储银行进行相关调查，如有违反，邮储银行有权采取以下一种或多种措施：（1）暂停或终止提供本协议项下快捷支付业务；（2）终止本协议；（3）取消客户的用卡资格。</p>
    <p className="blod">10、因电力中断、电脑、网络等通讯故障，或不可抗力等因素导致智能货柜快捷支付业务中断或者处于不稳定状态，邮储银行已尽力采取补救措施但仍未能避免损失的情况下，不承担相关责任。</p>
    <p className="blod">11、邮储银行有权变更、暂停或终止本协议，但必须于执行前通过邮储银行网站（http://www.psbc.com.cn）进行公告。客户在公告执行后继续办理智能货柜快捷支付业务的，视同接受有关本协议和智能货柜快捷支付业务服务修改、变更的内容。客户不同意的，可以在履行本协议项下应尽义务后解除本协议。</p>
    <p className="blod">12、如邮储银行与智能货柜业务合作终止后，自终止之日起本协议自动失效。客户如需注销快捷支付业务，客户可以通过智能货柜端发起注销申请，业务注销自邮储银行同意并在银行系统中设置成功后生效。</p>
    <p className="blod">13、客户在智能货柜快捷支付业务开通页面上点击确认，视同已通读上述条款，对黑体字加粗标注的条款的内容及其后果已充分理解，对所有内容均无异议。</p>
    <p className="blod">14、本协议自客户在智能货柜网站页面点击确认后立即生效。本协议的生效、履行及解释均适用中国法律。</p>
  </div>
	)
}