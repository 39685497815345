import React from 'react';
import { Skeleton } from 'antd-mobile';

type SkeleList = {
  lineCount: number
}

export default function Skele(props: SkeleList) {
  return (
    <React.Fragment>
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={props.lineCount} animated />
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={props.lineCount} animated />
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={props.lineCount} animated />
    </React.Fragment>
  )
}