import React, { useState, useEffect, Suspense } from 'react';
import http from '@/http/http';
import { orderType } from '@/constant/orderType';
import { Tabs, List, Toast, InfiniteScroll, Dialog, SpinLoading, Empty } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { scanType } from '@/constant/scanType';
import { useSelector, useDispatch } from 'react-redux';
import { checkSign, checkUserRegistry, getTokenAndUserInfo } from '@/utils/common';
import platform from '@/platform';
import { getLocal, setLocal } from '@/utils/storage';
import './supplyOrderList.less';
import Skele from '@/components/SkeleList/SkeleList';

type OrderType = {
  orderId: string,
  orderSn: string,
  [propsname: string]: any
}
// 去支付参数
type GoPayInfo = {
  orderSn: string
  sourceType: string | number
  isOrigin?: boolean
}

export default function OrderList() {

  let [orderList, setOrderList] = useState([] as OrderType[]); // 订单列表
  let [pageIndex, setPageIndex] = useState(1); // 当前页码
  let [isListFinish, setListFinish] = useState(false); // 列表数据是否加载完
  let [isRequest, setIsRequest] = useState(false); // 是否调用了请求
  // store中用户信息
  const globalUserInfo = useSelector((state: any) => {
    return state.userInfo.userInfo;
  })
  const userInfoStorage = getLocal('userInfo');
  let userInfo = Object.assign({}, globalUserInfo, userInfoStorage);
  const pageSize = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const platformObject = useSelector((state: any) => state.currentPlatform.platform)
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform)
  const changeTabHandle = () => {

  }

  /**
   * @desc 调用接口
  */
  const getOrderList = () => {
    setIsRequest(true);
    return new Promise((resolve, reject) => {
      http.post({
        url: '/api/cuser/v1/order/list',
        data: {
          pageIndex: pageIndex,
          pageSize: pageSize,
          searchCriteria: {
            orderType: orderType.UNPAY,
            orderSource: platformObject.memberSource
          }
        }
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
  /**
   * @desc 农行用户自动获取token
  */
  const automicTokenForAbc = (callback: Function) => {
    if (platformObject.platform === 'abc') {
      let abcUserId = platformObject.AbcUserId ?? platformObject.abcUserId;
      checkUserRegistry(abcUserId).then((res: any) => {
        if (res.code === 1 && res.data) { // 新用户
          navigate('/userLogin', { replace: true })
        } else {
          getTokenAndUserInfo(abcUserId).then((result: any) => {
            setLocal({ key: 'token', value: result.data.token });
            setLocal({ key: 'userInfo', value: result.data.memberInfo });
            dispatch({
              type: 'SET_TOKEN',
              value: result.data.token
            });
            dispatch({
              type: 'SET_USERINFO',
              value: result.data.memberInfo
            });
            callback()
          })
        }
      })
    } else {
      navigate('/userLogin', { replace: true })
    }
  }
  /**
   * @desc 列表数据解析
  */
  const resolveList = async () => {
    if (isListFinish) return;
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    console.log('pageIndex', pageIndex)
    console.log('orderList', orderList)
    let { code, data } = await getOrderList() as any;
    dispatch({
      type: 'SET_HIDE_LOADING'
    })
    if (code === 1) {
      if (data?.orders) {
        let list = [...orderList, ...data.orders] as OrderType[];
        setOrderList(list);
        if (data.orders.length === pageSize) {
          setPageIndex(pageIndex + 1);
        } else {
          let isFinish = true;
          setListFinish(isFinish)
        }
      } else {
        let isFinish = true;
        setListFinish(isFinish)
      }
    } else {
      const token = getLocal('token');
      if (!token) {
        automicTokenForAbc(resolveList)
      } else {
        Toast.show({
          icon: 'fail',
          content: '加载数据失败'
        })
      }
    }
  }

  /**
  * @desc 获取签约状态
 */
  const getUserSignStatus = async (orderSn: string) => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    const { code } = await checkSign(currentPlatform) as any;
    if (code === 1) { // 已经签约
      execPayOrder(orderSn)
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      let path = '';
      switch (currentPlatform) {
        case 'alipay':
          path = '/protocol';
          break;
        case 'abc':
          path = '/abc/sign';
          break;
        case 'psbc':
          path = '/psbc/sign';
          break;
        case 'cmb':
          const { code: signCode, data: signUrl, message: signMsg } = await platform.cmb.cardSign(userInfo) as { code: number, data: string, message: string }
          if (signCode === 1 && signUrl) {
            window.location.href = signUrl;
          } else {
            Toast.show({
              icon: 'fail',
              content: signMsg || '授权失败',
            })
          }
          break;
        default:
          break;
      }
      path && navigate(path, { state: { active: 1, isOpen: false } })
    }
  }
  /**
   * @desc 去支付
  */
  const payOrder = ({ orderSn, sourceType }: GoPayInfo) => {
    if (sourceType === scanType.WECHATID) {
      // 微信订单提示
      Dialog.confirm({
        title: '提示',
        content: <section>支付失败，请使用微信扫描货柜二维码进行支付，如有疑问，请联系客服或拨打电话 <a href="tel:029-86627864" className="tel">029-86627864</a></section>,
        closeOnMaskClick: false,
        confirmText: '联系客服',
        onConfirm: () => {
          const tel = document.querySelector('.tel') as any;
          tel.click()
        },
      })
      return;
    }
    getUserSignStatus(orderSn)
  }
  /**
   * @desc 调用支付
  */
  const execPayOrder = (orderSn: string) => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    http.get({
      url: `/api/cuser/v1/order/PayAgain/${orderSn}`,
    }).then(res => {
      if (res.code === 1) {
        if (currentPlatform === 'psbc' || currentPlatform === 'abc') {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          Toast.show({
            icon: 'success',
            content: '支付成功',
          })
          setListFinish(false)
          setOrderList([])
          setPageIndex(1)
          setTimeout(() => {
            resolveList()
          }, 0)
        } else {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          Toast.show({
            icon: 'success',
            content: res.data || res.msg || res.message || '支付成功',
          })
          setListFinish(false)
          setOrderList([])
          setPageIndex(1)
          setTimeout(() => {
            console.log('set-pageIndex', pageIndex);
            console.log('set-orderList', orderList)
            resolveList()
          }, 2000)
        }
      } else {
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        Toast.show({
          icon: 'fail',
          content: res.message || '支付失败，请重试',
        })
      }

    }).catch(() => {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: '支付失败，请重试',
      })
    })
  }

  /**
   * @desc 加载更多
  */
  const loadMore = async () => {
    return resolveList()
  }

  /**
   * @desc 查看订单详情
  */
  const OrderDetail = (orderSn: string) => {
    navigate('/orderDetail', { state: { orderSn } });
  }

  useEffect(() => {
    resolveList()
    platform[currentPlatform as keyof typeof platform].setTitle('订单列表')
    dispatch({
      type: 'SET_NAVTITLE',
      value: '订单列表'
    })
  }, [isListFinish])

  return (
    <Suspense fallback={<SpinLoading color='primary' />}>
      <section style={{ display: isRequest ? 'none' : 'block' }}>
        <Skele lineCount={7} />
      </section>
      <section className="empty" style={{ display: orderList?.length ? 'none' : 'block' }}>
        <Empty />
        <p className="empty-text">暂无数据</p>
      </section>
      <section className="OrderList" style={{ display: orderList?.length ? 'block' : 'none' }}>
        <section className="tab">
          <Tabs onChange={changeTabHandle}>
            {/* <Tabs.Tab title="全部" key="all">
            </Tabs.Tab>
            <Tabs.Tab title="待付款" key="unpay">
            </Tabs.Tab> */}
            {/* <Tabs.Tab title="已完成" key="finish">
            </Tabs.Tab>
            <Tabs.Tab title="已退款" key="refund">
            </Tabs.Tab> */}
          </Tabs>
        </section>
        <section>
          <List className="list" style={{ display: orderList?.length ? 'block' : 'none' }}>
            {orderList && orderList?.length && orderList.map(item => {
              return (
                <List.Item key={item.orderSn} arrow={false} className="order-item">
                  {/* <section> */}
                  <section className="location">
                    <span className="address">{item.deviceName}</span>
                  </section>
                  <ul className="product-box" style={{ display: item.orderInfos?.length ? 'block' : 'none' }}>
                    {item.orderInfos && item.orderInfos?.length && item.orderInfos.map((product: any, index: number) => {
                      const style = {
                        background: `url(${product.productThumbnailURI}) no-repeat center center`,
                        backgroundSize: 'cover'
                      }
                      return (
                        <li style={{ display: index < 2 ? 'block' : 'none' }} key={product.productName}>
                          <section className="product-info">
                            <section style={style} className="product-image"></section>
                            <dl>
                              <dt>
                                <span>{product.productName}</span>
                                <span className="count">x{product.productCount}</span>
                              </dt>
                              <dd>
                                <span className="payment">￥{product.originAmount || 0}</span>
                                <span className="real">￥{product.realAmount || 0}</span>
                              </dd>
                            </dl>
                          </section>
                        </li>
                      )
                    })}
                  </ul>
                  <section className="product-info">
                    <span className="title">订单编号:</span>
                    <span className="content">{item.orderSn}</span>
                  </section>
                  <section className="product-info">
                    <span className="title">订单时间:</span>
                    <span className="content">{item.createdTime}</span>
                  </section>
                  <section className="product-count">
                    <span className="total">共{item.productCount}件商品</span>
                    <span className="count">总价￥{item.originAmount || 0}</span>
                    <span>实付￥<span className="real-pay">{item.orderType === orderType.UNPAY && item.status !== 1 ? 0 : item.realAmount || 0}</span></span>
                  </section>
                  <section className="btn-group">
                    <span className="btn detail" onClick={() => OrderDetail(item.orderSn)}>详情</span>
                    <span style={{ display: item.orderType === orderType.UNPAY ? 'inline-block' : 'none' }}>
                      <span className="btn pay"
                        onClick={() => payOrder({ orderSn: item.orderSn, sourceType: item.paymentTypeId })}>去支付
                      </span>
                    </span>
                  </section>
                  {/* </section> */}
                </List.Item>
              )
            })}
          </List>
          <InfiniteScroll loadMore={loadMore} threshold={50} hasMore={!isListFinish} />
        </section>
      </section>
    </Suspense>
  )
}