import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './agreement.less';

export default function Agreement() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch({
      type: 'SET_NAVTITLE',
      value: '服务协议'
    })
  }, [])

	return(
		<div className="page-psbcAgreement">
    <h3>中国邮政储蓄银行数字人民币钱包协议支付服务协议</h3>

    <p className="blod">中国邮政储蓄银行数字人民币钱包（以下简称&ldquo;钱包&rdquo;）协议支付服务协议（以下简称&ldquo;本协议&rdquo;）是中国邮政储蓄银行股份有限公司（以下简称&ldquo;我行&rdquo;）与钱包用户（以下简称&ldquo;用户&rdquo;或&ldquo;您&rdquo;）就数字人民币钱包协议支付授权服务（以下简称&ldquo;本服务&rdquo;）的使用等相关事项所订立的有效合约。为了保障您的权益，在同意本协议之前，请您仔细阅读本协议的全部内容。<strong><strong>您通过网络页面点击确认或</strong></strong><strong><strong>以其他方式选择接受本协议则视为同意</strong></strong><strong><strong>本协议，表示您本人已阅读、充分理解并同意与</strong></strong><strong><strong>我行</strong></strong><strong><strong>达成协议，同时同意接受本协议的全部约定内容。若您对本协议的</strong></strong><strong><strong>任何</strong></strong><strong><strong>内容存在疑问，请您暂停后续操作，您可联系我行进行咨询，以便为您解答和说明。</strong></strong></p>

    <div className="main">
      <p><strong><strong>一、服务介绍</strong></strong></p>
      <p className="blod">1.1中国邮政储蓄银行数字人民币钱包协议支付服务：是指您开立的中国邮政储蓄银行数字人民币钱包经验证短信动态码后与第三方平台账户进行绑定，当您需要在第三方平台进行支付时，授权特定第三方平台在一定限额内向我行发送扣款指令，我行即可在不验证您的支付密码、短信动态码等信息的情况下，根据该特定第三方平台的指令从您的中国邮政储蓄银行数字人民币钱包中扣收并划转相应资金（因本服务涉及第三方平台，对于第三方平台在支付时的鉴权要求、额度要求，需遵循第三方平台的规则和要求）。</p>

      <p><strong><strong>二、本服务的功能</strong></strong></p>
      <p className="blod">2.1基于本服务涉及第三方平台，<strong><strong>如第三方平台对支付额度、支付鉴权有相关要求，</strong></strong><strong><strong>您同意</strong></strong><strong><strong>在遵从我行支付限额、支付鉴权要求基础上，同时遵从第三方平台的支付规则。</strong></strong></p>
      <p className="blod">2.2您可以在第三方平台相关页面查询您已授权的特定第三方信息，您也可以关闭本服务。在关闭后，钱包支付可能在部分场景下不能正常使用。</p>
      <p className="blod">2.3本服务不收费，<strong><strong>如后续有收费项目产生，</strong></strong><strong><strong>我行</strong></strong><strong><strong>将按照法律法规及监管规定提前以公告形式通知您收费标准及收费时间。</strong></strong></p>

      <p><strong><strong>三、本服务的使用</strong></strong></p>
      <p className="blod">3.1<strong><strong>您在此同意并确认，使用本服务的意思表示出自您的真实意愿；同时您对使用本服务过程中发出的指令的真实性及有效性承担全部责任，该指令视为您的真实意思表示且不可变更或撤销。您</strong></strong><strong><strong>承诺</strong></strong><strong><strong>，</strong></strong><strong><strong>我行</strong></strong><strong><strong>依照您的指令进行操作的一切风险由您承担。</strong></strong></p>
      <p className="blod">3.2您承诺不会利用本服务从事任何非法的或违反本协议目的或者侵犯其他第三方权益的行为，否则我行有权单方面终止提供本服务，并不承担任何责任；如您给我行造成损失的，您应按照法律规定承担全部赔偿责任。</p>
      <p className="blod">3.3您授权特定第三方平台向我行发送扣款指令，并授权我行根据该特定第三方平台的指令从您邮储银行数字人民币钱包中扣取指定款项至该特定第三方指定的钱包。您了解并确认您授权的特定第三方平台包括为您提供服务的商户、商户与您共同确认的第三方平台。</p>
      <p className="blod">3.4<strong><strong>您在此同意并确认，如因您本人钱包内未留有足额数字人民币的余款，或被解绑、冻结、销户等原因，导致无法支付交易金额所造成的一切经济纠纷或违约责任等全部由您本人承担。因不可归责于</strong></strong><strong><strong>我行</strong></strong><strong><strong>的事由，导致的不能及时划付款项、划账错误等责任</strong></strong><strong><strong>，</strong></strong><strong><strong>我行不予承担</strong></strong><strong><strong>。您确认，因特定第三方平台的原因导致您遭受经济损失的，</strong></strong><strong><strong>应</strong></strong><strong><strong>由您与特定第三方平台协商解决。</strong></strong></p>
      <p className="blod">3.5<strong><strong>您在此同意并确认，&ldquo;中国邮政储蓄银行数字人民币钱包</strong></strong><strong><strong>协议</strong></strong><strong><strong>支付服务&rdquo;是</strong></strong><strong><strong>我行</strong></strong><strong><strong>为您提供的增值服务，</strong></strong><strong><strong>我行</strong></strong><strong><strong>是被授权指令的执行方，对支付指令的真实性和准确性不负有审查义务，除非我行未按照您本人的授权及您本人指定的商户的指令操作或执行指令错误，</strong></strong><strong><strong>否则</strong></strong><strong><strong>我行将不对任何因本协议之履行而导致的您的任何直接损失和间接损失承担法律责任。您如果对使用&ldquo;中国邮政储蓄银行数字人民币钱包</strong></strong><strong><strong>协议</strong></strong><strong><strong>支付服务&rdquo;的具体业务类型及交易金额有异议，或因您购买商品或服务而与特定第三方平台产生任何纠纷，请自行与您的特定第三方平台协商处理或与其通过司法途径解决。</strong></strong></p>
      <p className="blod">3.6您予以接受并确认，基于风险控制的需要，我行对您使用本服务进行支付时的场景、资金渠道、支付额度等进行限制，具体限制以页面提示为准。同时，我行有权根据客观情况在风险控制时降低您的免密交易额度和次数，若您不同意我行对上述内容所作的修改、修订或解释，您应立即停止使用本协议约定的&ldquo;中国邮政储蓄银行数字人民币钱包协议支付服务&rdquo;。</p>
      <p className="blod">3.7除本协议另有规定外，我行在提前通知您或在网站公告的情况下，可以单方面终止向您提供本服务，并不承担任何赔偿责任，除非法律规定我行应当赔偿。我行在终止提供本服务后，若发现您之前存在违法或违反本协议目的的使用行为，给我行造成损失的，我行仍可据此要求您承担相应赔偿责任并保留通过司法途径予以解决的权利。</p>

      <p><strong><strong>四、隐私保护</strong></strong></p>
      <p className="blod">4.1我行重视保护您的隐私，您的个人隐私信息将根据《中国邮政储蓄银行数字人民币钱包个人信息保护政策》受到保护与规范。</p>

      <p><strong><strong>五、其他</strong></strong></p>
      <p className="blod">5.1为了进一步改善用户体验，我行将会持续更新服务，为用户提供版本升级、功能升级、规则升级等服务和内容的更新，本协议也可能会随之更新，更新内容将于公布之日起生效。如果您不同意我行对上述内容所作的修改、修订或解释，您应立即停止使用本协议约定的&ldquo;中国邮政储蓄银行数字人民币钱包协议支付服务&rdquo;。一旦您继续使用&ldquo;中国邮政储蓄银行数字人民币钱包协议支付服务&rdquo;，视为您理解并接受我行对上述内容的修改、修订或解释。如果更新的内容涉及您的主要权利或责任，我行会以公告、官方网站（www.psbc.com）通知等方式向您进行提示。</p>
      <p className="blod">5.2双方在履行本协议的过程中，如发生争议，应协商解决。协商不成的，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。</p>
      <p className="blod">5.3本协议的成立、生效、履行和解释，均适用中华人民共和国法律；法律无明文规定的，应适用我行制定的业务规则和通行的商业惯例。本协议未规定部分依照《中国邮政储蓄银行数字人民币钱包用户服务协议》及相关附属规则予以履行；本协议与《中国邮政储蓄银行数字人民币钱包用户服务协议》及相关附属规则相冲突的，以本协议为准。 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

      <p className="blod"><strong><strong>本协议自您勾选同意《中国邮政储蓄银行数字人民币钱包</strong></strong><strong><strong>协议</strong></strong><strong><strong>支付服务》之日起生效，您依据本协议的约定而进行的全部授权亦同时生效；您的全部授权在</strong></strong><strong><strong>我行</strong></strong><strong><strong>收到您本人递交的解除本协议的申请时终止；在终止之前</strong></strong><strong><strong>我行</strong></strong><strong><strong>依照您本人的授权进行操作的一切风险均由您本人自行承担。</strong></strong><strong><strong>如需进行业务咨询和投诉，请拨打邮储银行客服热线：95580。</strong></strong></p>
    </div>
  </div>
	)
}