import React, { useState, useEffect } from 'react';
import { purchasType } from '@/constant/purchasType'; // code状态
import { useSelector, useDispatch } from 'react-redux';
import { Toast, Dialog } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { scanType } from '@/constant/scanType';
import platform from '@/platform';
import http from '@/http/http';
import { appendFrontendLog, checkoutDeviceStatus, checkUserStatus, checkUnpayOrder, checkUserRegistry, getTokenAndUserInfo } from '@/utils/common';
import { AlipaySquareFill } from 'antd-mobile-icons';
import { setLocal } from '@/utils/storage';
import './Purchas.less';

// 商品列表item
type ProductListItem = {
  imageUrl: string,
  goodName: string,
  price: number | string
}

// 订单详情金额信息
type PaymentInfo = {
  realAmount: number
}

// 订单详情商品信息
type OrderProductItem = {
  productName: string,
  productPrice: number | string,
  count: number | string
}

// 订单详情
type OrderDetail = {
  paymentInfo: PaymentInfo
  productInfos?: OrderProductItem[]
}

let codeTimer: NodeJS.Timer | null | undefined = null; // 获取code定时器
let openTimer: NodeJS.Timer | null | undefined = null; // 开门12s定时器

let orderSn = '';
export default function Purchas() {
  const platformObject = useSelector((state: any) => state.currentPlatform.platform)
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform)
  let { doorType, whichDoor } = useSelector((state: any) => state.globalDoorType.doorType)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DEFAULT_CODE = 1;
  // let orderSn = '';
  let [ productList, setProductList ] = useState([] as ProductListItem[]); // 商品列表
  // let [ orderSn, setOrderSn ] = useState(''); // 订单编号
  let [ orderOrDeviceStatus, setOrderOrDeviceStatus ] = useState(DEFAULT_CODE); // 初始状态
  let [ orderDetail, setOrderDetail ] = useState({} as OrderDetail); // 订单详情
  let [ openDoorSecond, setOpenSecond ] = useState(12); // 开门倒计时
  let [ scanErrorMessage, setScanErrorMessage ] = useState(''); // 开门错误信息展示

  const deviceId = useSelector((state: any) => { // 设备id
    return state.globalDeviceId.deviceId;
  })

/**
   * @desc 农行用户自动获取token
  */
const automicTokenForAbc = (callback: Function) => {
  if(platformObject.platform === 'abc') {
    let abcUserId = platformObject.AbcUserId ?? platformObject.abcUserId;
    checkUserRegistry(abcUserId).then((res: any) => {
      if(res.code === 1 && res.data) { // 新用户
        navigate('/userLogin', { replace: true })
      } else {
        getTokenAndUserInfo(abcUserId).then((result: any) => {
          setLocal({ key: 'token', value: result.data.token });
          setLocal({ key: 'userInfo', value: result.data.memberInfo });
          dispatch({
            type: 'SET_TOKEN',
            value: result.data.token
          });
          dispatch({
            type: 'SET_USERINFO',
            value: result.data.memberInfo
          });
          callback()
        })
      }
    })
  } else {
    navigate('/userLogin', { replace: true })
  }
}

  let checkOrderStatus = async () => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    let { code, data } = await checkUnpayOrder(platformObject.memberSource) as any;
    if(code === 1) {
      if(data && data.length) {
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        let item = data[0];
        if(item.source === scanType.WECHAT) { // 微信订单
          Dialog.confirm({
            title: '温馨提示',
            content: <section>您有未支付的微信小程序订单,为避免影响您后续购物,请前往微信小程序完成支付。如有疑问请联系客服或拨打电话 <a href="tel:029-86627864" onClick={() => {
              Dialog.clear()
            }}>029-86627864</a>。</section>,
            closeOnMaskClick: false,
            confirmText: '确认',
            cancelText: '取消',
            onConfirm() {
              console.log('Confirmed');
              navigate('/home', { replace: true })
            },
            onCancel() {
              navigate('/home', { replace: true })
            }
          })
        } else { // 其他订单
          Dialog.confirm({
            title: '温馨提示',
            content: <section>您有未支付的订单,为避免影响您后续购物,请完成支付。如有疑问请联系客服或拨打电话 <a href="tel:029-86627864" onClick={() => {
              Dialog.clear()
            }}>029-86627864</a>。</section>,
            closeOnMaskClick: false,
            confirmText: '去支付',
            cancelText: '取消',
            onConfirm() {
              navigate('/orderDetail', { state: { orderSn: item.orderNo }, replace: true })
            },
            onCancel() {
              navigate('/home', { replace: true })
            },
          })
        }
      } else {
        checkDeviceStatus();
      }
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      automicTokenForAbc(checkOrderStatus)
    }
    appendFrontendLog({
      label: 'checkUnpayOrder',
      content: JSON.stringify({code, data})
    })
  }

  let checkDeviceStatus = async () => {
    const { code, data, msg } = await checkoutDeviceStatus(deviceId) as { code: number, data: any, msg: string }
    appendFrontendLog({
      label: 'checkDeviceStatus',
      content: JSON.stringify({code, data, msg})
    })
    if(code !== 1) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: msg || '设备不可用',
      })
      dispatch({
        type: 'SET_DEVICEID',
        value: ''
      })
      navigate('/home', { replace: true })
    } else {
      if(!data.isSuccess) {
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        Toast.show({
          icon: 'fail',
          content: msg || '设备不可用',
        })
        dispatch({
          type: 'SET_DEVICEID',
          value: ''
        })
        navigate('/home', { replace: true })
      } else {
        if(data.doorType === 0) { // 单开门
          checkUser()
        } else if(data.doorType === 1) { // 双开门
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          dispatch({
            type: 'SET_SELECTDOOR',
            value: true
          })
        }
      }
    }
  }

  const checkUser = async () => {
    const { code } = await checkUserStatus() as {code: number};
    appendFrontendLog({
      label: 'checkUserStatus',
      content: JSON.stringify({code})
    })
    if(code !== 1 && code !== undefined) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Dialog.confirm({
        title: '提示',
        content: '网络异常，请稍后再试',
        closeOnMaskClick: false,
        confirmText: '确认',
        cancelText: '取消',
        onConfirm() {
          navigate('/home', { replace: true })
          console.log('Confirmed')
        },
        onCancel() {
          navigate('/home', { replace: true })
        }
      })
    } else {
      resolveOpenDoor()
    }
  }
  /**
   * @desc 获取当前设备商品列表
  */
  const GetProductList = () => {
    return new Promise((resolve, reject) => {
      http.post({
        url: '/api/cuser/V1/Customer/GetGoodPrices',
        data: {
          deviceSn: deviceId
        }
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 解析商品列表
  */
  const resolveProduct = async () => {
    const { code, data } = await GetProductList() as any;
    if(code === 1) {
      setProductList(data)
    }
  }

  /**
   * @desc 调用开门方法
  */
  const openDoor = () => {
    return new Promise((resolve, reject) => {
      http.post({
        url: '/api/cuser/V1/Customer/ScanCode',
        data: {
          deviceSn: deviceId,
          source: platformObject.memberSource,
          doorType,
          whichDoor
        }
      }).then(res => {
        if(res?.code && res?.data) {
          resolve(res)
        } else {
          resolve({ code: 0, data: '', msg: '', message: '' })
        }
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 开门后状态解析
  */
  let resolveOpenDoor = async () => {
    const { code, data, msg, message } = await openDoor() as any;
    dispatch({
      type: 'SET_HIDE_LOADING'
    })
    dispatch({
      type: 'SET_DOORTYPE',
      value: {
        doorType: 0,
        whichDoor: 1
      }
    })
    dispatch({
      type: 'SET_DEVICEID',
      value: ''
    })
    if(code === 1) {
      orderSn = data.orderSn;
      // setOrderSn(orderSn)
      resolveMessageCode && resolveMessageCode()
    } else {
      setScanErrorMessage(msg || message || '订单异常')
      formatTitle(msg || message || '订单异常')
      setOrderOrDeviceStatus(code)
    }
    appendFrontendLog({
      label: 'ScanCode',
      content: JSON.stringify({code, data})
    })
  }

  /**
   * @desc 获取状态 Code
  */
  const getMessageCode = () => {
    return new Promise((resolve, reject) => {
      http.get({
        url: '/api/cuser/V1/Member/GetMessage',
        data: {},
        isLoading: false
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  const formatTitle = (title: string) => {
    platform[currentPlatform as keyof typeof platform].setTitle(title)
    dispatch({
      type: 'SET_NAVTITLE',
      value: title
    })
  }

  /**
   * @desc 解析状态 Code
  */
  let resolveMessageCode = () => {
    codeTimer = setInterval(async () => {
      const { code, orderNo } = await getMessageCode() as any;
      if(orderSn !== orderNo) return;
      switch (code) {
        case purchasType.DEVICE_USED: // 设备占用中
          if(orderOrDeviceStatus !== code) {
            clearGetCodeTimer()
            formatTitle('有人购买中')
          }
          break;
        case purchasType.REQ_ERROR: // 设备异常
        case purchasType.SCAN_ERROR:
          if(orderOrDeviceStatus !== code) {
            clearGetCodeTimer()
            formatTitle('异常')
          }
          break;
        case purchasType.OPEN_REQUEST: // 请求开锁
          if(orderOrDeviceStatus !== code) {
            formatTitle('开锁中')
          }
          break;
        case purchasType.LOCK_OPENED: // 锁已开
          if(orderOrDeviceStatus !== code) {
            formatTitle('开锁成功')
            OpenDoorReverse()
          }
          break;
        case purchasType.DOOR_OPENED: // 门已开
          if(orderOrDeviceStatus !== code) {
            formatTitle('请取货')
          }
          break;
        case purchasType.OPENDOOR_TIMEOUT: // 开门超时
          if(orderOrDeviceStatus !== code) {
            clearGetCodeTimer()
            formatTitle('开门超时')
          }
          break;
        case purchasType.DOOR_CLOSED: // 门已关
          if(orderOrDeviceStatus !== code) {
            formatTitle('已关门')
          }
          break;
        case purchasType.ORDER_CLOSING: // 订单结算中
          if(orderOrDeviceStatus !== code) {
            formatTitle('结算中')
            ResolveOrderDetail()
          }
          break;
        case purchasType.ORDER_FINISH: // 订单结算完成
          if(orderOrDeviceStatus !== code) {
            clearGetCodeTimer()
            formatTitle('结算成功')
            ResolveOrderDetail()
          }
          break;
        case purchasType.ORDER_ERROR: // 结算失败
          if(orderOrDeviceStatus !== code) {
            clearGetCodeTimer()
            formatTitle('结算失败')
            ResolveOrderDetail()
          }
          break;
        default:
          break;
      }
      if(orderOrDeviceStatus !== code && code > 0) {
        setOrderOrDeviceStatus(code)
      }
    }, 1000 * 2);
  }

  /**
   * @desc 清除获取code的定时器
  */
  const clearGetCodeTimer = () => {
    codeTimer && clearInterval(codeTimer);
  }

  /**
   * @desc 返回首页
  */
  const goBackHome = () => {
    navigate('/home', { replace: true })
  }

  /**
   * @desc 获取订单详情
  */
  const orderDetails = () => {
    return new Promise((resolve, reject) => {
      http.get({
        url: `/api/cuser/v1/order/details/${orderSn}`
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 订单详情解析
  */
  const ResolveOrderDetail = async () => {
    const { code, data } = await orderDetails() as any;
    if(code === 1) {
      setOrderDetail(data);
    }
    appendFrontendLog({
      label: 'orderDetails',
      content: JSON.stringify({ code, data })
    })
  }

  /**
   * @desc 开门倒计时
  */
  const OpenDoorReverse = () => {
    if(openTimer) return;
    let timeCount = 12;
    openTimer = setInterval(() => {
      setOpenSecond(timeCount - 1)
      timeCount -= 1;
      if(timeCount <= 0) {
        setOpenSecond(0)
        openTimer && clearInterval(openTimer)
      }
    }, 1000)
  }

  const appendLog = () => {
    console.log('orderSn', orderSn)
    appendFrontendLog({
      label: 'GetMessage',
      content: JSON.stringify({ code: orderOrDeviceStatus, orderSn })
    })
  }

  /**
   * @desc 双开门判断开哪边门
  */
  useEffect(() => {
    console.log('doorType', doorType);
    if(!doorType) return;
    if(doorType === 1) {
      dispatch({
        type: 'SET_SHOW_LOADING'
      })
      // dispatch({
      //   type: 'SET_DOORTYPE',
      //   value: {
      //     doorType: 0,
      //     whichDoor: 1
      //   }
      // })
      checkUser()
    }
  }, [ doorType ])

  useEffect(() => {
    appendLog()
  }, [ orderOrDeviceStatus ])

  useEffect(() => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    resolveProduct()
    formatTitle('开门中')
    checkOrderStatus()

    return () => {
      checkOrderStatus = (function() {}) as () => Promise<any>
      checkDeviceStatus = (function() {}) as () => Promise<any>
      resolveOpenDoor = (function() {}) as () => Promise<void>
      resolveMessageCode = function() {}
      if(codeTimer) {
        clearInterval(codeTimer);
        codeTimer = null;
      }
      if(openTimer) {
        clearInterval(openTimer);
        openTimer = null;
      }
      appendFrontendLog({
        label: 'UnloadPage',
        content: JSON.stringify({ code: orderOrDeviceStatus, orderSn })
      })
    }
  }, [])

  return(
    <section className="Purchas">

      {/* 设备占用 */}
      <section className="state-box device-used"
        style={{ display: orderOrDeviceStatus === purchasType.DEVICE_USED ? 'block' : 'none' }}>
        <section className="icon-fail"></section>
        <p className="tip fail-tip">有人购买中，请稍等</p>
        <article>有人正在购买中，请稍等片刻再次购买。</article>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <section className="bottom-banner"></section>
      </section>

      {/* 订单异常 */}
      <section className="state-box anomaly"
        style={{ display: orderOrDeviceStatus === purchasType.REQ_ERROR || orderOrDeviceStatus === purchasType.SCAN_ERROR ? 'block' : 'none' }}>
        <section className="icon-fail"></section>
        <p className="tip fail-tip">{ scanErrorMessage }</p>
        <article>请联系客服。</article>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <section className="bottom-banner"></section>
      </section>

      <section className="view-box"
       style={{ display: [ purchasType.OPEN_REQUEST, purchasType.LOCK_OPENED, purchasType.DOOR_OPENED ].includes(orderOrDeviceStatus) ? 'block' : 'none'}}>
        {/* 开门中 */}
        <section className="state-box lock-opening"
          style={{ display: [ purchasType.OPEN_REQUEST ].includes(orderOrDeviceStatus) ? 'block' : 'none' }}>
          <section className="icon-opening"></section>
          <p className="tip common-tip">正在开锁</p>
          <article>请勿将购买的商品放回货柜，如有问题，请联系客服。</article>
        </section>

        {/* 开锁成功 */}
        <section className="state-box lock-opened"
          style={{ display: orderOrDeviceStatus === purchasType.LOCK_OPENED ? 'block' : 'none' }}>
          <section className="icon-opened"></section>
          <p className="tip common-tip">开锁成功，请取货</p>
          <article>门锁已开，请在<span className="openTimer">{openDoorSecond}</span>秒内开门取走商品，取完商品后请关上门。请勿将商品放回货柜。如有问题，请联系客服。</article>
        </section>

        {/* 已开门 */}
        <section className="state-box door-opened"
          style={{ display: orderOrDeviceStatus === purchasType.DOOR_OPENED ? 'block' : 'none' }}>
          <section className="icon-opened"></section>
          <p className="tip common-tip">门已开，请取货</p>
          <article>门已打开，请取走商品，取完商品后请关上门。请勿将商品放回货柜。如有问题，请联系客服。</article>
        </section>
      </section>

      {/* 开门超时 */}
      <section className="state-box door-timeout"
        style={{ display: orderOrDeviceStatus === purchasType.OPENDOOR_TIMEOUT ? 'block' : 'none' }}>
        <section className="icon-timeout"></section>
        <p className="tip fail-tip">开门超时</p>
        <article>如锁已开，请正常开门购物，关门后会自动结算。如果锁没有开，请重试或尝试其他智能货柜无人售货柜。</article>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <section className="bottom-banner"></section>
      </section>

      {/* 已关门 */}
      <section className="state-box door-closed"
        style={{ display: orderOrDeviceStatus === purchasType.DOOR_CLOSED ? 'block' : 'none' }}>
        <section className="icon-opening"></section>
        <p className="tip common-tip">已关门</p>
        <article>关好门之后可以放心离开，不必等待，3-5分钟后将自动扣款。</article>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <section className="bottom-banner"></section>
      </section>

      {/* 订单结算中 */}
      <section className="state-box order-payment"
        style={{ display: orderOrDeviceStatus === purchasType.ORDER_CLOSING ? 'block' : 'none' }}>
        <section className="icon-payment"></section>
        <p className="tip">订单结算中</p>
        <p className="mount">￥{orderDetail.paymentInfo?.realAmount}</p>
        <section className="order-title" style={{ display: currentPlatform === 'alipay' ? 'block' : 'none' }}>
          <AlipaySquareFill fontSize={16} color='var(--adm-color-primary)'/>
          <span>支付宝先购后付</span>
        </section>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <p className="amount-count">
          <span>合计</span>
          <span className="mount">￥{orderDetail.paymentInfo?.realAmount}</span>
        </p>
        <ul className="product-list">
          { orderDetail.productInfos && orderDetail.productInfos.length > 0 && orderDetail.productInfos.map(item => {
            return (
              <li className="product-item" key={item.productName}>
                <section>{ item.productName }</section>
                <section className="order-product-count">
                  <span>￥{ item.productPrice }</span>
                  <span>x{ item.count }</span>
                </section>
              </li>
            )
          })}
        </ul>
      </section>

      {/* 订单结算完成 */}
      <section className="state-box order-finish"
        style={{ display: orderOrDeviceStatus === purchasType.ORDER_FINISH ? 'block' : 'none' }}>
        <section className="icon-finish"></section>
        <p className="tip">{ orderDetail.productInfos && orderDetail.productInfos.length > 0 ? '您已支付成功' : '您本次购物已结束' }</p>
        <p className="mount">￥{orderDetail.paymentInfo?.realAmount || 0}</p>
        <section className="order-title" style={{ display: currentPlatform === 'alipay' ? 'block' : 'none' }}>
          <AlipaySquareFill fontSize={16} color='var(--adm-color-primary)'/>
          <span>支付宝先购后付</span>
        </section>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <p className="amount-count">
          <span>合计</span>
          <span className="mount">￥{orderDetail.paymentInfo?.realAmount || 0}</span>
        </p>
        <ul className="product-list">
          { orderDetail.productInfos && orderDetail.productInfos.length > 0 && orderDetail.productInfos.map(item => {
            return (
              <li className="product-item" key={item.productName}>
                <section>{ item.productName }</section>
                <section className="order-product-count">
                  <span>￥{ item.productPrice }</span>
                  <span>x{ item.count }</span>
                </section>
              </li>
            )
          })}
        </ul>
      </section>

      {/* 订单结算失败 */}
      <section className="state-box order-finish"
        style={{ display: orderOrDeviceStatus === purchasType.ORDER_ERROR ? 'block' : 'none' }}>
        <section className="icon-finish"></section>
        <p className="tip">结算失败</p>
        <p className="mount">￥{orderDetail.paymentInfo?.realAmount || 0}</p>
        <section className="order-title" style={{ display: currentPlatform === 'alipay' ? 'block' : 'none'}}>
          <AlipaySquareFill fontSize={16} color='var(--adm-color-primary)'/>
          <span>支付宝先购后付</span>
        </section>
        <section className="home-btn" onClick={goBackHome}>返回首页</section>
        <p className="amount-count">
          <span>合计</span>
          <span className="mount">￥{orderDetail.paymentInfo?.realAmount || 0}</span>
        </p>
        <ul className="product-list">
          { orderDetail.productInfos && orderDetail.productInfos.length > 0 && orderDetail.productInfos.map(item => {
            return (
              <li className="product-item" key={item.productName}>
                <section>{ item.productName }</section>
                <section className="order-product-count">
                  <span>￥{ item.productPrice }</span>
                  <span>x{ item.count }</span>
                </section>
              </li>
            )
          })}
        </ul>
      </section>

      {/* 商品信息列表 */}
      <section className="device-product-list"
        style={{ display: [ DEFAULT_CODE, purchasType.OPEN_REQUEST, purchasType.LOCK_OPENED, purchasType.DOOR_OPENED ].includes(orderOrDeviceStatus) && productList.length ? 'block' : 'none' }}>
        <h3>商品信息</h3>
        <ul className="product-box">
          { productList && productList.length && productList.map((item, index) => {
              let style = {
                background: `url(${item.imageUrl}) no-repeat center center`,
                backgroundSize: 'contain'
              }
              return (
                <li className="product-item" key={index}>
                  <section className="product-image" style={ style }></section>
                  <dl>
                    <dt>{ item.goodName }</dt>
                    <dd>￥{ item.price }</dd>
                  </dl>
                </li>
              )
            })
          }
        </ul>
      </section>
    </section>
  )
}