let globalDevice = {
  deviceId: ''
}

export default function globalDeviceId(state = globalDevice, action: any) {
  switch(action.type) {
    case 'SET_DEVICEID':
      return { deviceId: action.value }
    default:
      return state;
  }
}