import { Toast } from 'antd-mobile';
interface IObjectKeys {
  [key: string]: string;
}

/**
 * 解析url中的参数
 * @param {String} url
 * @return {Object}
**/
export const queryStringParse = (url:string) => {
  let parseUrl = url || window.location.href;
  let queryObj: IObjectKeys = {};
  if (parseUrl.indexOf('?') === -1) return queryObj;
  let arr = parseUrl.split('?')[1].match(/[\w\d]+=[\w\d\/\+=%]+/g);
  arr && arr.map(item => {
    let index = item.indexOf('=');
    let key = item.substr(0, index)
    let value = item.substr(index + 1);
    queryObj[key] = decodeURIComponent(value);
  })
  return queryObj;
}

type Json = {
  [propname: string]: any
}
/**
 * 将JSON转为queryString形式的字符串
 * @param {*} json {a:1,b:2}
 * @return a=1&b=2
 */
 export const jsonToQueryString = (json: Json) => {
  if (!json) return;

  return Object.keys(json).map(key => {
    if (json[key] === undefined)
      return ''
    return `${
      decodeURIComponent(key)
    }=${
      decodeURIComponent(json[key])
    }`
  }).join('&')
}

/**
 * XML简单的通过节点名称获取值
 * @param {XML String} xml
 * @param {String} node
 */
 export const getTextByNode = (xml: string, node: string) => {
  const regRule = (new RegExp(`<${node}>(.+?)<\/${node}>`, 'g')) as any;
  let text = regRule.exec(xml)[1];
  return text;
}

/**
 * @desc 生成随机数
*/
export const randomNumber = (length: number) => {
  const basic = '123456789';
  let random = '';
  for(let i = 0; i < length; i++) {
    random += Math.floor(Math.random() * basic.length)
  }
  return random;
}

/**
  * @desc 检测手机号是否合法
*/
export const checkPhoneValid = (phone: string) => {
  if(!phone) {
    Toast.show({
      icon: 'fail',
      content: '请输入手机号',
    })
    return false;
  }
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  let isVaild = true;
  if(!reg.test(phone)) {
    Toast.show({
      icon: 'fail',
      content: '手机号输入错误',
    })
    isVaild = false;
  }
  return isVaild;
}

/**
 * @desc 检测身份证号是否合法
*/
export const checkCertificateValid = (certificate: string) => {
  if(!certificate) {
    Toast.show({
      icon: 'fail',
      content: '请输入身份证号',
    })
    return false;
  }
  const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  let isVaild = true;
  if(!reg.test(certificate)) {
    Toast.show({
      icon: 'fail',
      content: '身份证号输入错误',
    })
    isVaild = false;
  }
  return isVaild;
}

/**
 * @desc 图片置灰
*/
export const isGrayScalImage = () => {
  const humiliy = ['05/12', '07/07', '09/18', '12/13'];
  const now = new Date();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const humiDay = `${month}/${day}`;
  return humiliy.includes(humiDay)

}