let globalDoorType = {
  doorType: {
    doorType: 0,
    whichDoor: 1
  }
}

export default function currentDoorType(state = globalDoorType, action: any) {
  switch(action.type) {
    case 'SET_DOORTYPE':
      return { doorType: action.value }
    default:
      return state;
  }
}