import { Dialog } from 'antd-mobile';

class Psbc {
  
  scan() {
    Dialog.alert({
      content: '暂不支持，请使用邮储APP扫一扫',
      confirmText: '知道了',
      onConfirm: () => {
        console.log('Confirmed')
      },
    })
  }

  setTitle(title: string) {
    // document.title = title;
  }

  sign() {}

  closeWindow() {
    (window as any).AlipayJSBridge.call('exitApp', null, () => {
      console.log('exitApp')
    })
  }

}

export default new Psbc();