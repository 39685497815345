import React, { useState, useEffect } from 'react';
import Tabbar from '@/components/Tabbar/Tabbar';
import { scanType } from '@/constant/scanType';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Toast } from 'antd-mobile';
import {
  authCheck,
  getUserInfo,
  checkUnpayOrder,
  checkoutDeviceStatus,
  checkSign,
  checkUserStatus,
  checkThirdUser,
  appendFrontendLog,
  checkUserRegistry,
  getTokenAndUserInfo,
  getSystemStatus
} from '@/utils/common';
import { setLocal, getLocal, setSession, getSession } from '@/utils/storage';
import platform from '@/platform';
import userAgent from '@/utils/agent';
import { queryStringParse } from '@/utils/tool';
import http from '@/http/http';
import { SignConfig } from '@/type/cmbsign';
import './Home.less';

export default function Home() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [ isScanBtnDisabled, setScanBtn ] = useState(true);
  let [ openIdForAbc, setAbcOpenId ] = useState(''); // 农行回跳地址openId

  let platformObject = useSelector((state: any) => state.currentPlatform.platform);
  let currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  let currentCmbUserId = useSelector((state: any) => state.currentPlatform.platform.cmbUserId);
  // store中用户信息
  const globalUserInfo = useSelector((state: any) => {
    return state.userInfo.userInfo;
  })
  const userInfoStorage = getLocal('userInfo')
  let userInfo = Object.assign({}, globalUserInfo, userInfoStorage);

  /**
   * @desc 点击扫码按钮，除了招行其他银行不调用摄像头; 先检查是否有token，再检查是否有未支付订单
  **/
  const ScanCodeHandle = async () => {
    if(currentPlatform !== 'alipay' && currentPlatform !== 'cmb') {
      platform[currentPlatform as keyof typeof platform].scan();
      return;
    }
    if(isScanBtnDisabled) return;
    setScanBtn(true)
    const userAuth = await authCheck();
    if(userAuth) {
      if(currentPlatform === 'alipay') {
        const { deviceId } = await platform[currentPlatform as keyof typeof platform].scan() as { deviceId: string };
        console.log('deviceId', deviceId)
        if(!deviceId) {
          setScanBtn(false)
        } else {
          dispatch({
            type: 'SET_SHOW_LOADING'
          })
          OrderStatus(deviceId)
        }
        appendFrontendLog({
          label: 'ScanCodeHandle',
          content: JSON.stringify({ userAuth, deviceId })
        })
      } else {
        console.log('currentCmbUserId', currentCmbUserId)
        if(!currentCmbUserId) {
          CmbLogin().then(async cmbUserId => {
            console.log('scan', cmbUserId)
            if(cmbUserId) {
              dispatch({
                type: 'SET_PLATFORM',
                value: {
                  cmbUserId,
                  ...platformObject
                }
              })
              const { deviceId } = await platform[currentPlatform as keyof typeof platform].scan() as { deviceId: string };
              if(!deviceId) {
                setScanBtn(false)
              } else {
                dispatch({
                  type: 'SET_SHOW_LOADING'
                })
                OrderStatus(deviceId, cmbUserId)
              }
            } else {
              // 没有同意授权
              setScanBtn(false)
            }
          });
        } else {
          const { deviceId } = await platform[currentPlatform as keyof typeof platform].scan() as { deviceId: string };
          if(!deviceId) {
            setScanBtn(false)
          } else {
            dispatch({
              type: 'SET_SHOW_LOADING'
            })
            OrderStatus(deviceId, currentCmbUserId)
          }
          appendFrontendLog({
            label: 'ScanCodeHandle',
            content: JSON.stringify({ userAuth, deviceId })
          })
        }
      }
    } else {
      if(currentPlatform === 'cmb') {
        if(!currentCmbUserId) {
          CmbLogin().then(async cmbUserId => {
            if(cmbUserId) {
              dispatch({
                type: 'SET_PLATFORM',
                value: {
                  cmbUserId,
                  ...platformObject
                }
              })
              setScanBtn(false)
              navigate('/userLogin', { replace: true })
            } else {
              // 没有同意授权
              setScanBtn(false)
            }
          });
        } else {
          setScanBtn(false)
          navigate('/userLogin', { replace: true })
        }
      } else {
        const { authCode } = await platform.alipay.sign() as { authCode?: string, error?: number };
        if(authCode) {
          const { code, data } = await getUserInfo(authCode) as any;
          if(code === 1) {
            data.token && setLocal({ key: 'token', value: data.token })
            setLocal({ key: 'userInfo', value: data.memberInfo })
            if(data.memberInfo != null && data.memberInfo.mobile !== '') {
              dispatch({
                type: 'SET_USERINFO',
                value: data.memberInfo
              })
              const { deviceId } = await platform.alipay.scan() as { deviceId: string };
              if(!deviceId) {
                setScanBtn(false)
              } else {
                dispatch({
                  type: 'SET_SHOW_LOADING'
                })
                OrderStatus(deviceId)
              }
            } else {
              setScanBtn(false)
              navigate('/userLogin', { replace: true })
            }
          } else {
            setScanBtn(false)
          }
          appendFrontendLog({
            label: 'getUserInfo',
            content: JSON.stringify(data)
          })
        } else { // 用户取消授权
          setScanBtn(false)
        }
      }
    }
  }

  /**
   * @desc 获取三方id
  */
  const getStoreThirdId = (customerId?: string) => {
    let thirdUserId = '';
    switch(currentPlatform) {
      case 'abc':
        thirdUserId = customerId || platformObject.AbcUserId;
        break;
      case 'psbc':
        thirdUserId = customerId || platformObject.customerId;
        break;
      case 'cmb':
        thirdUserId = customerId || platformObject.cmbUserId;
        break;
      case 'alipay':
        thirdUserId = customerId || '';
        break;
      default:
        break;
    }
    return thirdUserId;
  }

  /**
   * @desc 农行用户自动获取token
  */
  const automicTokenForAbc = (deviceId: string, callback: Function) => {
    if(platformObject.platform === 'abc') {
      let abcUserId = platformObject.AbcUserId ?? platformObject.abcUserId;
      checkUserRegistry(abcUserId).then((res: any) => {
        if(res.code === 1 && res.data) { // 新用户
          navigate('/userLogin', { replace: true })
        } else {
          getTokenAndUserInfo(abcUserId).then((result: any) => {
            setLocal({ key: 'token', value: result.data.token });
            setLocal({ key: 'userInfo', value: result.data.memberInfo });
            dispatch({
              type: 'SET_TOKEN',
              value: result.data.token
            });
            dispatch({
              type: 'SET_USERINFO',
              value: result.data.memberInfo
            });
            appendFrontendLog({
              label: 'home getTokenAndUserInfo',
              content: JSON.stringify({ abcUserId, result })
            })
            callback(deviceId)
          })
        }
        appendFrontendLog({
          label: 'home checkUserRegistry',
          content: JSON.stringify({ platformObject, res })
        })
      })
    } else {
      navigate('/userLogin', { replace: true })
    }
  }
  /**
   * @desc 是否有未支付
   * @param {String} deviceId 设备id
  **/
  const OrderStatus = async (deviceId: string, customerId?: string) => {
    const userAuth = await authCheck();
    if(userAuth) {
      console.log('platformObject', platformObject)
      const { code: thirdCode } = await checkThirdUser({
        thirdUserId: getStoreThirdId(customerId),
        memberSource: platformObject.memberSource || platformObject.MemberSource,
      }) as any;
      if(thirdCode === 1) {
        let { code, data, msg, message } = await checkUnpayOrder(platformObject.memberSource || platformObject.MemberSource) as any;
        let alipayModalShow = false;
        let wechatModalShow = false;
        if(code === 1) {
          if(data && data.length) {
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            let item = data[0];
            if(item.source === scanType.WECHAT) { // 微信订单
              if(!wechatModalShow) {
                wechatModalShow = true;
                Dialog.confirm({
                  title: '温馨提示',
                  content: <section>您有未支付的微信小程序订单,为避免影响您后续购物,请前往微信小程序完成支付。如有疑问请联系客服或拨打电话 <a href="tel:029-86627864" onClick={() => {
                    Dialog.clear()
                  }}>029-86627864</a>。</section>,
                  closeOnMaskClick: false,
                  confirmText: '确认',
                  cancelText: '取消',
                  onConfirm() {
                    console.log('Confirmed')
                  },
                  onCancel() {

                  }
                })
              }
            } else { // 其他订单
              if(!alipayModalShow) {
                alipayModalShow = true;
                Dialog.confirm({
                  title: '温馨提示',
                  content: <section>您有未支付的订单,为避免影响您后续购物,请完成支付。如有疑问请联系客服或拨打电话 <a href="tel:029-86627864" onClick={() => {
                    Dialog.clear()
                  }}>029-86627864</a>。</section>,
                  closeOnMaskClick: false,
                  confirmText: '去支付',
                  cancelText: '取消',
                  onConfirm() {
                    navigate('/orderDetail', { state: { orderSn: item.orderNo }})
                  },
                  onCancel() {

                  },
                })
              }
            }
            setScanBtn(false)
          } else { // 没有未支付
            if(!deviceId) return;
            console.log('deviceId', deviceId)
            const { code } = await checkUserStatus() as { code: number };
            appendFrontendLog({
              label: 'checkUserStatus',
              content: JSON.stringify({code})
            })
            if(code === 1) {
              if(deviceId) {
                DeviceEnable(deviceId)
              } else {
                dispatch({
                  type: 'SET_HIDE_LOADING'
                })
                Toast.show({
                  icon: 'fail',
                  content: '请扫正确的设备码',
                })
                setScanBtn(false)
              }
            } else if(code !== 1 && code !== undefined) {
              dispatch({
                type: 'SET_HIDE_LOADING'
              })
              setScanBtn(false)
              Dialog.confirm({
                title: '提示',
                content: '网络异常，请稍后再试',
                closeOnMaskClick: false,
                confirmText: '确认',
                cancelText: '取消',
                onConfirm() {
                  console.log('Confirmed')
                },
                onCancel() {

                }
              })
            }
          }
        } else {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          Toast.show({
            icon: 'fail',
            content: msg || message || data.message || '系统异常，请稍后再试',
          })
          setScanBtn(false)
        }
        appendFrontendLog({
          label: 'WaitPayOrder',
          content: JSON.stringify({code, data})
        })
      } else {
        setLocal({ key: 'token', value: '' })
        if(currentPlatform === 'abc' || currentPlatform === 'psbc' || currentPlatform === 'cmb') {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          dispatch({
            type: 'SET_DEVICEID',
            value: deviceId
          })
          automicTokenForAbc(deviceId, OrderStatus)
        } else {
          const { authCode } = await platform.alipay.sign() as { authCode?: string, error?: number };
          if(authCode) {
            const { code, data } = await getUserInfo(authCode) as any;
            if(code === 1) {
              data.token && setLocal({ key: 'token', value: data.token })
              setLocal({ key: 'userInfo', value: data.memberInfo })
              if(data.memberInfo != null && data.memberInfo.mobile !== '') {
                dispatch({
                  type: 'SET_USERINFO',
                  value: data.memberInfo
                })
                setScanBtn(false)
                if(!deviceId) {
                  dispatch({
                    type: 'SET_HIDE_LOADING'
                  })
                  setScanBtn(false)
                } else {
                  OrderStatus(deviceId)
                }
              } else {
                dispatch({
                  type: 'SET_HIDE_LOADING'
                })
                setScanBtn(false)
                navigate('/userLogin', { replace: true })
              }
              appendFrontendLog({
                label: 'getUserInfo-Scan',
                content: JSON.stringify(data)
              })
            } else {
              dispatch({
                type: 'SET_HIDE_LOADING'
              })
              setScanBtn(false)
            }
          } else { // 用户取消授权
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            setScanBtn(false)
          }
          // setScanBtn(false)
        }
      }
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      setScanBtn(false)
      automicTokenForAbc(deviceId, OrderStatus)
    }
  }

  /**
   * @desc 判断是否签约数字货币
  */
  const checkDwSignStatus = () => {
    let url = '';
    switch(currentPlatform) {
      case 'abc':
        url = '/api/cuser/V1/Member/IsAbcDigitalWalletSign';
        break;
      case 'psbc':
        url = '/api/cuser/V1/Member/IsPsbcDigitalWalletSign'
        break;
      default:
        break;
    }
    const data = { phone: userInfo.mobile }
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 设备状态
   * @param {String} deviceId
  **/
  const DeviceEnable = async (deviceId: string) => {
    dispatch({
      type: 'SET_DEVICEID',
      value: deviceId
    })
    setLocal({ key: 'globalDeviceId', value: deviceId })
    const { code, data, msg } = await checkoutDeviceStatus(deviceId) as any;
    if(code === 0) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: msg || '设备不可用',
      })
      setScanBtn(false)
    } else if(code === 1) {
      resolveProtocol()
    } else if(code === -101) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      setScanBtn(false)
      automicTokenForAbc(deviceId, DeviceEnable)
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      setScanBtn(false)
      Toast.show({
        icon: 'fail',
        content: msg || data.msg || data.message,
      })
    }
    appendFrontendLog({
      label: 'DeviceEnable',
      content: JSON.stringify({code, data, msg})
    })
  }

  /**
   * @desc 检查是否签约
  */
  const resolveProtocol = async () => {
    const { code } = await checkSign(currentPlatform) as any;
    appendFrontendLog({
      label: 'checkSign',
      content: JSON.stringify({code})
    })
    if(code === 1) { // 签约成功，开始调用开门
      setScanBtn(false)
      if(currentPlatform === 'alipay' || currentPlatform === 'cmb') {
        navigate('/purchas')
      } else {
        const { code: signCode, data, msg } = await checkDwSignStatus() as any;
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        console.log('signCode', signCode, data)
        if(signCode === 1 && data) {
          navigate('/purchas')
        } else if(signCode === 1 && !data){
          Dialog.confirm({
            title: '提示',
            content: '是否签约数字人民币免密支付购物?',
            closeOnMaskClick: false,
            confirmText: '确认',
            cancelText: '取消',
            onConfirm() {
              navigate(`/${currentPlatform}/sign`, { state: { active: 1, isOpen: true } })
            },
            onCancel() {
              navigate('/purchas')
            }
          })
        } else {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          Toast.show({
            icon: 'fail',
            content: msg || data.msg || data.message || '系统异常，请稍后重试',
          })
        }
      }
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      setScanBtn(false)
      let path = '';
      switch(currentPlatform) {
        case 'alipay':
          path = '/protocol';
          break;
        case 'abc':
          path = '/abc/sign';
          break;
        case 'psbc':
          path = '/psbc/sign';
          break;
        case 'cmb':
          const { code: signCode, data: signUrl, message: signMsg } = await platform.cmb.cardSign(userInfo) as { code: number, data: string, message: string }
          if(signCode === 1 && signUrl) {
            window.location.href = signUrl;
          } else {
            Toast.show({
              icon: 'fail',
              content: signMsg || '授权失败',
            })
          }
          break;
        default:
          break;
      }
      path && navigate(path, { state: { active: 1, isOpen: true }})
    }
  }

  /**
   * @desc 招行登陆授权
  */
  const CmbLogin = async () => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    const { code, data } = await platform.cmb.login() as any;
    if(code === 1) {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      const signConfig = await platform.cmb.signDialog(data) as SignConfig;
      console.log('CmbLogin', signConfig)
      if(signConfig.errCode) {
        setScanBtn(false)
        return;
      }
      dispatch({
        type: 'SET_SHOW_LOADING'
      })
      const { code: signCode, data: cmbUserId } = await platform.cmb.sign(signConfig) as { code: number, data: string };
      if(signCode === 1) {
        // 返回用户id
        return cmbUserId;
      } else {
        // 没有授权
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        setScanBtn(false)
      }
    } else {
      setScanBtn(false)
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
    }
  }


  useEffect(() => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    dispatch({
      type: 'SET_NAVTITLE',
      value: '岩心智能'
    })
    /**
     * @desc 获取环境，设置platform
    */
    function resolvePlatform() {
      let ua = userAgent();
      let uaValue = 0;
      console.log('ua', ua)
      switch(ua) {
        case 'alipay':
          uaValue = scanType.ALIPAY;
          break;
        case 'psbc':
          uaValue = scanType.PSBC;
          break;
        case 'abc':
          uaValue = scanType.ABC;
          break;
        case 'cmb':
          uaValue = scanType.CMB;
          break;
        default:
          uaValue = scanType.ALIPAY;
          break;
      }
      dispatch({
        type: 'SET_PLATFORM',
        value: {
          platform: ua || 'alipay',
          memberSource: uaValue,
          signedDigitalCurrency: true
        }
      })
      return ua;
    }
    /**
     * @desc 从扫一扫进入处理逻辑
    **/
    async function ResolveAlipayScan(deviceId:string) {
      if(deviceId) {
        const userAuth = await authCheck();
        if(userAuth) {
          OrderStatus(deviceId)
        } else {
          const { authCode } = await platform.alipay.sign() as { authCode?: string, error?: number };
          if(authCode) {
            const { code, data } = await getUserInfo(authCode) as any;
            if(code === 1) {
              data.token && setLocal({ key: 'token', value: data.token })
              setLocal({ key: 'userInfo', value: data.memberInfo })
              if(data.memberInfo != null && data.memberInfo.mobile !== '') {
                dispatch({
                  type: 'SET_USERINFO',
                  value: data.memberInfo
                })
                OrderStatus(deviceId)
              } else {
                dispatch({
                  type: 'SET_HIDE_LOADING'
                })
                setScanBtn(false)
                navigate('/userLogin', { replace: true })
              }
            } else {
              dispatch({
                type: 'SET_HIDE_LOADING'
              })
              setScanBtn(false)
              navigate('/userLogin', { replace: true })
            }
            appendFrontendLog({
              label: 'getUserInfo',
              content: JSON.stringify(data)
            })
          } else { // 用户取消授权
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            setScanBtn(false)
          }
        }
      } else {
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        setScanBtn(false)
      }
    }

    // const sysStatus = async () => {
    //   const data = await getSystemStatus()
    //   console.log('getsystem', data)
    // }
    // sysStatus();
    setLocal({ key: 'globalDeviceId', value: '' });
    // 判断是从扫一扫进入还是路由进入
    console.log('url', window.location.href)
    appendFrontendLog({
      label: 'URL PATH',
      content: JSON.stringify({ url: window.location.href })
    })
    let loginSession = getSession('homeEntry')
    const ua = currentPlatform || resolvePlatform();
    setLocal({ key: 'platform', value: ua })
    currentPlatform && platform[currentPlatform as keyof typeof platform].setTitle('岩心智能');
    if(!loginSession && currentPlatform) {
      setScanBtn(true)
      setSession({ key: 'homeEntry', value: 'entry' })
      console.log(window.location.href)
      const query = queryStringParse(window.location.href);
      const deviceId = queryStringParse(window.location.href).deviceid ||
       queryStringParse(window.location.href).deviceNum ||
       (queryStringParse(window.location.href).qrcode && 
       queryStringParse(decodeURI(atob(queryStringParse(window.location.href).qrcode))).deviceid);
      console.log('deviceId', deviceId)
      const openId = queryStringParse(window.location.href).openId;
      const customerId = queryStringParse(window.location.href).customerId;
      if(openId) {
        setAbcOpenId(openId)
        let platformObj = {
          ...platformObject,
          AbcUserId: openId,
        }
        dispatch({
          type: 'SET_PLATFORM',
          value: platformObj
        })
      }
      if(!deviceId) {
        setScanBtn(false)
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        return;
      }
      setLocal({ key: 'globalDeviceId', value: deviceId });
      if(ua === 'alipay') { // 支付宝
        ResolveAlipayScan(deviceId)
      } else if(ua === 'abc') { // 农行
        dispatch({
          type: 'SET_SHOW_LOADING'
        })
        if(openId) {
          setAbcOpenId(openId)
          let platformObj = {
            ...platformObject,
            AbcUserId: openId,
          }
          appendFrontendLog({
            label: 'Set Before PlatformObject',
            content: JSON.stringify({platformObj, platformObject})
          })
          dispatch({
            type: 'SET_PLATFORM',
            value: platformObj
          })
          dispatch({
            type: 'SET_DEVICEID',
            value: deviceId
          })
          appendFrontendLog({
            label: 'Set After PlatformObject',
            content: JSON.stringify(platformObject)
          })
          OrderStatus(deviceId, openId)
        } else {
          dispatch({
            type: 'SET_HIDE_LOADING'
          })
          platform.abc.init(deviceId);
        }
      } else if(ua === 'psbc') {
        dispatch({
          type: 'SET_SHOW_LOADING'
        })
        let value = Object.assign({}, platformObject, query);
        dispatch({
          type: 'SET_PLATFORM',
          value
        })
        dispatch({
          type: 'SET_DEVICEID',
          value: deviceId
        })
        OrderStatus(deviceId, customerId)
      } else if(ua === 'cmb') {
        dispatch({
          type: 'SET_SHOW_LOADING'
        })
        let value = Object.assign({}, platformObject);
        dispatch({
          type: 'SET_PLATFORM',
          value
        })
        CmbLogin().then(cmbUserId => {
          if(cmbUserId) {
            dispatch({
              type: 'SET_PLATFORM',
              value: {
                ...platformObject,
                cmbUserId,
              }
            })
            if(!deviceId) {
              dispatch({
                type: 'SET_HIDE_LOADING'
              })
              setScanBtn(false)
              return;
            } else {
              dispatch({
                type: 'SET_DEVICEID',
                value: deviceId
              })
            }
            OrderStatus(deviceId, cmbUserId)
          } else {
            // 没有同意授权
            dispatch({
              type: 'SET_HIDE_LOADING'
            })
            setScanBtn(false)
          }
        });
      }
    } else {
      setScanBtn(false)
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
    }

    return () => {
      setScanBtn(false)
    }
  }, [currentPlatform, openIdForAbc])

  return (
    <section className="Home">
      <section className="swiper"></section>
      <section className="banner"></section>
      <section className="scan-btn" onClick={ScanCodeHandle}></section>
      <footer>
        <Tabbar />
      </footer>
    </section>
  )
}