import CryptoJS from 'crypto-js';
const key = 'weilaixiansencry';

export function encryptByAES(data: Object | string) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export function decrytpFromAES(data: string) {
  let result = null;
  try{
    let bytes = CryptoJS.AES.decrypt(data, key);
    result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch(error) {
    result = null;
  }
  return result;
}