import React from 'react';
import { ErrorBlock, Space } from 'antd-mobile';

export default function upgrade() {
  const title = <h4>系统维护中</h4>
  const description = <span>抱歉，设备暂时无法使用，请稍后再试！</span>;
  return(
    <section>
      <Space block direction='vertical' style={{ '--gap': '16px' }}>
        <ErrorBlock
        status='default'
        fullPage
        description={description}
        title={title}/>
      </Space>
    </section>
  )
}