let globalToken = {
  token: ''
}

export default function userInfo(state = globalToken, action: any) {
  switch(action.type) {
    case 'SET_TOKEN':
      return {
        token: action.value
      }
    default:
      return state;
  }
}