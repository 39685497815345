import { jsonToQueryString, queryStringParse, getTextByNode } from '@/utils/tool';
import http from '@/http/http';
import { SignConfirmConfig, SignConfig } from '@/type/cmbsign';

type Parame = {
  [propname: string]: any
}


class Cmb {

  public api = {
    scan: 'http://CMBLS/dimensionalbarcode',
    share: 'http://cmbls/socialshare',
    topBar: 'http://cmbls/TopBarUI'
  }

  /**
   * @desc 招行初始化相关
  */
  init(api: string, params: Parame) {
    let apiUrl = `${api}?${jsonToQueryString(params)}`;
    console.log(apiUrl)

    if ((window as any).cmblsExecutor) {
      (window as any).cmblsExecutor.executeCmbls("1.0", apiUrl);
    } else {
      document.addEventListener('CMBLSExecutorReady', () => {
        (window as any).cmblsExecutor = {};
        (window as any).cmblsExecutor.executeCmbls("1.0", apiUrl);
      }, false);
    }

    if (!(window as any).CMBLS) {
      (window as any).CMBLS = {};
    }
  }

  /**
   * @desc 扫码
  */
  scan() {
    return new Promise((resolve, reject) => {
      this.init(this.api.scan, { id: 'wlxsScan' });
      (window as any).CMBLS.dimensionalBarcode = {
        successCallback(id: any, message: any) {
          let decodeUrl = getTextByNode(message, 'decodedString');
          let url = window.atob(decodeUrl);
          console.log(url)
          let deviceId = queryStringParse(url)['deviceid'];

          if (!deviceId) {
            deviceId = queryStringParse(url)['sim'];
          }

          resolve({ deviceId })
        },
        failCallback(id: any, message: any) {
          let errorMsg = getTextByNode(message, 'description');
          console.log(errorMsg)
          resolve({ message })
        }
      };
    })
  }

  /**
   * @desc 设置页面标题
  */
  setTitle(title: string) {
    document.title = title;
  }

  /**
   * @desc 登陆
  */
  login() {
    return new Promise((resolve, reject) => {
      http.post({
        url: '/api/cuser/V1/Member/CmbFormAuthInfo',
        data: {}
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
  /**
   * @desc 提示授权
  */
  signDialog(config: SignConfirmConfig) {
    return new Promise((resolve, reject) => {
      config.reAuth = true;
      (window as any).cmblapi.merchantLogin({
        ...config,
        success(res: any) {
          console.log(res)
          resolve(res)
        },
        fail(error: Error) {
          console.log(error)
          resolve(error)
        }
      })
    })
  }

  /**
   * @desc 授权
  */
  sign(signconfig: SignConfig) {
    return new Promise((resolve, reject) => {
      http.post({
        url: '/api/cuser/V1/Member/CmbAuthLogin',
        data: {
          ...signconfig
        }
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 招行登陆成功获取跳转签约参数
  */
   cardSign(info: any) {
    let url = '/api/cuser/V1/Member/CmbSignUrl';
    let data = {
      thirdId: info.thirdPartyUserId
    }
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data
      }).then(res => {
        console.log('CmbSignUrl', res)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 关闭当前页面
  */
  closeWindow() {
    (window as any).cmblapi.popWindow()
  }
}

const cmb = new Cmb()

export default cmb