import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getLocal, setLocal } from '@/utils/storage';
import store from '@/store';

// 创建axios的实例
const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  timeout: 30000,
  validateStatus() {
    return true;
  },

  // 传递给 then/catch 前，允许修改响应数据
  transformResponse: [(data) => {
    if (typeof data === 'string' && data.startsWith('{')) {
      data = JSON.parse(data);
    }
    return data;
  }]
});


// 请求拦截器
service.interceptors.request.use((config: AxiosRequestConfig) => {
  // const token = localStorage.getItem('token') || 'WDO52qZCj4LJHxmLuPNW8JWGPL/b9iaE46/DHl62ksdJcBfwJZ19YoKDHMFMBlWmCgNmzKJGKcPtNZuRMI+KBi0gZhN6LSk+dEGsCZfWm8UHf8wpNNbwUXfomXZmCtIv';
  const token = getLocal('token');
  if(!config) {
    config = {};
  }
  if(!config.headers) {
    config.headers = {};
  }
  if(token) {
    config.headers.auth = token;
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
  }else {
    config.headers['Content-Type'] = 'application/json; charset=UTF-8';
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use((res: AxiosResponse) => {
  switch(res.status) {
    case 200:
      if(res?.data?.code === -100) {
        setLocal({key: 'token', value: ''});
        store.dispatch({
          type: 'SET_TOKEN',
          value: ''
        })
      }
      return res.data;
    case 401:
      store.dispatch({
        type: 'SET_TOKEN',
        value: ''
      })
      break;
    default:
      return res;
  }
}, error => {
  return Promise.reject(error);
});

export default service;
