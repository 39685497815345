import { encryptByAES, decrytpFromAES } from '@/utils/aes';

type storage = {
  key: string
  value: Object | string
}

/**
 * @desc 设置localStorage
*/
export function setLocal(options: storage) {
  let { key, value } = options;
  localStorage.setItem(key, encryptByAES(value));
}

/**
 * @desc 读取localStorage
*/
export function getLocal(key: string) {
  let localString = localStorage.getItem(key);
  let result = localString ? decrytpFromAES(localString) : '';
  return result;
}

/**
 * @desc 设置sessionStorage
*/
export function setSession(options: storage) {
  let { key, value } = options;
  sessionStorage.setItem(key, encryptByAES(value));
}

/**
 * @desc 读取sessionStorage
*/
export function getSession(key: string) {
  let sessionString = sessionStorage.getItem(key);
  let result = sessionString ? decrytpFromAES(sessionString) : '';
  return result;
}