// 订单类型
export const orderType = {
  NORMAL: 1, // 销售订单
  REFUND: 2, // 退款订单
  UNPAY: 3, // 未支付/补扣订单
}

// 销售订单状态
export const orderNormalStatus = {
  INVALID: -1, // 无效
  CREATED: 0, // 已创建
  COMPLATED: 1, // 已完成
  BUCKFAILURE: 2, // 扣款失败
  RECOGNIZED: 3, // 已识别
}

// 退款订单状态
export const orderRefundStatus = {
  REFUNDING: 0, // 退款中
  REFUND: 1, // 已退款
  REFUNDFAIL: 2, // 退款失败
}

// 补扣订单状态
export const orderUnpayStatus = {
  DEDUCTING: 0, // 补扣中
  DEDUCTED: 1, // 已补扣
  DEDUCTFAILURE: 2, // 补扣失败
  DEDUCTCONFIRM: 3, // 已确认
}