import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './SelectDoor.less';

export default function SelectDoor(options: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let showLoading = useSelector((state: any) => state.showLoading.status);

  const selectOpenDoor = (type: string) => {
    sessionStorage.setItem('selectdoor', 'YES')
    dispatch({
      type: 'SET_DOORTYPE',
      value: {
        doorType: 1,
        whichDoor: type === 'left' ? 1 : 2
      }
    })
    dispatch({
      type: 'SET_SELECTDOOR',
      value: false
    })
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
  }

  const closeOpenDoor = (e: any) => {
    dispatch({
      type: 'SET_SELECTDOOR',
      value: false
    })
    setTimeout(() => {
      if(!sessionStorage.getItem('selectdoor')) {
        navigate(-1);
      }
      sessionStorage.setItem('selectdoor', '')
    }, 100)
  }

  return (
    <section className="select-door-box" onClick={(e) => {closeOpenDoor(e)}}>
      <section className="select-content">
        <p>单次购物仅支持一个柜子</p>
        <section className="door-list">
          <dl onClick={() => selectOpenDoor('left')}>
            <dt>左柜</dt>
            <dd></dd>
          </dl>
          <dl onClick={() => selectOpenDoor('right')}>
            <dt>右柜</dt>
            <dd></dd>
          </dl>
        </section>
      </section>
    </section> 
  )
}