let globalSelectDoor = {
  selectDoorType: false
}

export default function currentDoorType(state = globalSelectDoor, action: any) {
  switch(action.type) {
    case 'SET_SELECTDOOR':
      return { selectDoorType: action.value }
    default:
      return state;
  }
}