import React from 'react';
import './Loading.less';

export default function Loading() {
  return (
    <section className="Loading">
      <span className="icon"></span>
      <p>请求中</p>
    </section>
  )
}