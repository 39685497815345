export const purchasType = {
  DEVICE_USED: -4, // 设备占用中
  REQ_ERROR: 0, // 请求开锁失败,设备异常
  OPEN_REQUEST: 1, // 请求开锁
  LOCK_OPENED: 2, // 锁已开
  DOOR_OPENED: 3, // 门已开
  OPENDOOR_TIMEOUT: 4, // 开门超时
  DOOR_CLOSED: 5, // 门已关
  ORDER_CLOSING: 6, // 订单结算中
  ORDER_FINISH: 7, // 订单结算完成
  ORDER_ERROR: 8, // 订单结算失败
  SCAN_ERROR: 1001,
}