import http from '@/http/http';
import { scanType } from '@/constant/scanType';
import { getLocal } from '@/utils/storage';

/**
 * @desc 检查用户授权
*/
export function authCheck() {
  return new Promise((resolve, reject) => {
    try{
      let token = getLocal('token');
      if(token != null && token !== '') {
        resolve(true)
      } else {
        resolve(false)
      }
    } catch(err) {
      resolve(false)
    }
  })
}

/**
 * @desc 获取用户信息
 * @param {String} authCode 用户授权码
*/
export function getUserInfo(authCode: string) {
  return new Promise((resolve, reject) => {
    http.post({
      url: '/api/cuser/v1/member/login',
      data: {
        code: authCode,
        memberSource: scanType.ALIPAY
      }
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

type thirdUser = { 
  thirdUserId: string
  memberSource: number
}
/**
 * @desc 验证第三方id是否合法
*/
export function checkThirdUser({ thirdUserId, memberSource }: thirdUser) {
  return new Promise((resolve, reject) => {
    http.post({
      url: '/api/cuser/V1/Member/IsValidThirdUser',
      data: {
        thirdUserId,
        memberSource,
      }
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
  
}

/**
 * @desc 检查用户是否签约免密代扣
*/
export function checkSign(platform: string) {
  let type = platform === 'alipay' ? 'Aliyun' : platform;
  return new Promise((resolve, reject) => {
    http.get({
      url: `/api/cuser/V1/Member/CheckSigning/${type}`,
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * @desc 检查是否有未支付订单
*/
export function checkUnpayOrder(orderSource: number) {
  return new Promise((resolve, reject) => {
    http.get({
      url: `/api/cuser/V1/order/WaitPayOrder?orderSource=${orderSource}`,
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * @desc 检查用户状态
*/
export function checkUserStatus() {
  return new Promise((resolve, reject) => {
    http.get({
      url: '/api/cuser/v1/Member/CheckUserIsForbidden'
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * @desc 检查设备状态
*/
export function checkoutDeviceStatus(deviceId: string) {
  return new Promise((resolve, reject) => {
    http.get({
      url: `/api/cuser/v1/Customer/${deviceId}/enable`,
      data: {}
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * @desc 检查当前系统状态是否正常
*/
export function getSystemStatus() {
  return new Promise((resolve, reject) => {
    http.get({
      url: '/api/sysmanagement/v1/param/repair'
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * @desc 获取农行用户是否新注册
*/
export function checkUserRegistry(abcUserId: string) {
  return new Promise((resolve, reject) => {
    http.post({
      url: '/api/cuser/V1/Member/IsFirstAbcLogin',
      data: {
        memberSource: scanType.ABC,
        abcUserId,
      },
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * @desc 农行用户获取token和用户信息相关
*/
export function getTokenAndUserInfo(abcUserId: string) {
  return new Promise((resolve, reject) => {
    http.post({
      url: '/api/cuser/V1/Member/GenTokenForAbc',
      data: {
        memberSource: scanType.ABC,
        abcUserId,
      },
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

type FrontendLog = {
  label: string
  content: string
  source?: string
  orderSn?: string
  user?: string
  deviceId?: string
}

/**
 * @desc 日志写入
*/
export function appendFrontendLog(data: FrontendLog) {
  const env = process.env.REACT_APP_ENV === 'development' ? 'development' : 'production';
  const globalUserInfo = getLocal('userInfo');
  const globalDeviceId = getLocal('globalDeviceId');
  const platform = getLocal('platform').toUpperCase();
  const logData = Object.assign({}, {
    deviceId: globalDeviceId,
    source: `APP-${platform}`,
    env,
    user: globalUserInfo?.mobile
  }, data)
  try{
    return new Promise((resolve, reject) => {
      http.log({ data: logData }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  } catch(e) {

  }

}