import React, { useEffect } from 'react';
import './agreement.less';

export default function agreement() {
	return(
		<div className="page-abcAgreement">
	    <h3>中国农业银行股份有限公司授权支付服务三方协议</h3>
	    <p className="blod">尊敬的客户，欢迎您使用授权支付服务。为了维护您的权益，请在签署本协议前，仔细阅读本协议各条款（特别是含有黑体字标题或黑体字文字的条款），注意本协议涉及数量和质量、价款或者费用、履行期限和方式、安全注意事项和风险警示、售后服务、民事责任、管辖等内容的条款。请关注个人金融信息保护的相关法律法规，了解自己的权利义务，合理审慎提供个人金融信息，维护自身合法权益。如您有疑问或不明之处，请咨询您的律师和有关专业人士。如需进行业务咨询和投诉，请拨打农业银行客服热线：95599。</p>
	    <p className="blod">《授权支付服务三方协议》已经由<u>西安岩心智能科技有限公司</u>（即为甲方）和中国农业银行股份有限公司（即为乙方）共同签署，您（即为丙方）点击确认即接受协议各条款，本协议生效。</p>
	    <div className="main">
	      <p>第一条  授权支付方式说明</p>
	      1.丙方为甲方电子商务平台上的实名注册客户，持有甲方注册账户。<br/>
	      2.丙方为乙方电子银行客户或已开通短信通知的银行卡客户，并且丙方需在其电子银行注册账户中或已开通短信通知的银行卡账户中指定账户作为扣款账户。<br/>
	      3.丙方按照甲方和乙方提供的操作流程将其在甲方的注册账户和在乙方的扣款账户进行绑定。<br/>
	      4.丙方进行网上支付时，如果选择使用授权支付方式的，扣款只需提供其在甲方的注册账户及安全认证信息。甲方根据客户的实名注册信息和客户选择授权支付方式的信息向乙方发出扣款指令，乙方按照甲方的扣款指令从丙方的扣款账户中进行扣款。<br/>
	      <p>第二条  甲方的权利和义务</p>
	      1.甲方应当在乙方开立结算账户。<br/>
	      2.甲方应当为丙方使用授权支付方式提供使用指引。<br/>
	      3.丙方选择使用授权支付方式后，甲方应当及时、准确地向乙方发送扣款指令。甲方不得篡改丙方指令，不得假冒丙方指令，也不得增加扣款金额；甲方如实施上述行为，致使乙方提供扣款等支付结算服务被丙方提出权利主张的，由甲方负责处理并承担全部责任，由此给丙方、乙方造成的损失由甲方负责赔偿。<br/>
	      4.甲方负责向丙方提供商品或者服务、业务查询、咨询、投诉、打印及发送发票等电子商务服务。<br/>
	      5.甲方应当保证交易数据的安全性、保密性、完整性和不可抵赖性，并负责处理因安全认证不足而产生的差错交易及风险交易，承担相关责任。<br/>
	      6.甲方通过非柜面渠道向非同名银行账户和支付账户转账的日累计限额、笔数和年累计限额等，已在甲乙双方之间另行签订的相关协议中约定，各方将按其约定执行。<br/>
	      <p>第三条  乙方的权利和义务</p>
	      1.乙方按照甲方的扣款指令实施扣款行为，将丙方款项划转至甲方在乙方开立的账户，为丙方和甲方提供支付结算服务。乙方对甲方发送的扣款指令不做实质性审查。<br/>
	      2.乙方对以下情况不承担责任：<br/>
	      （1）甲方发送的扣款指令信息不完整、不明确、存在乱码等无法执行扣款指令的；<br/>
	      （2）甲方故意或过失发送错误的扣款指令，乙方据此指令执行后发生扣款差错的；<br/>
	      （3）因甲方提供虚假证明文件致使客户资金被错误扣划的；<br/>
	      （4）丙方与甲方因交易关系产生纠纷的；<br/>
	      （5）丙方账户余额不足的；<br/>
	      （6）丙方或者甲方账户被依法冻结无法实施扣款行为的；<br/>
	      （7）其他不可抗力或者不可归责于乙方的情况。<br/>
	      <p>第四条  丙方的权利和义务</p>
	      1.丙方应当按照甲方和乙方提供的指引，正确指定扣款账户，注册实名账户，并将实名账户和扣款账户绑定。<br/>
	      2.乙方执行扣款指令后，丙方不能要求变更或者撤销扣款指令。<br/>
	      3.丙方同意，其使用甲方的实名注册账户并选择授权支付方式的，乙方即可实施扣款行为。丙方应当妥善保管本人在甲方的实名注册账户信息及相关密码，
	      不得向他人透露，因丙方泄露实名注册账户信息和密码造成的资金损失，甲乙双方不承担责任。<br/>
	      4.丙方应妥善保管本人在乙方的银行卡关键信息（密码、有效期、CVN2码、绑定的手机号等）、网上银行证书及其密码、身份证件信息，不得泄露上述信息。因丙方保管不善造成的损失，甲乙双方不承担责任。 <br/>
	      5.丙方知悉并同意，对于甲方电子商务平台和乙方网上支付系统而言，使用丙方拥有的相关账户、证书和密码即视为丙方行为，行为后果由丙方承担。<br/>
	      6.丙方应当按照甲方电子商务平台公布的收费标准向甲方支付授权支付服务的各项费用。<br/>
	      7.丙方通过非柜面渠道向非同名银行账户和支付账户转账的日累计限额、笔数和年累计限额等，已由乙丙双方另行约定，各方将按其约定执行。<br/>
	      <p>第五条  异常账务处理</p>
	      1.丙方发现扣款金额与其交易金额不符的，应当及时向甲方进行查询或者投诉，由甲方和乙方核对扣款信息。<br/>
	      2.确因甲方安全认证不足或其他等原因造成丙方账户扣款差错的，由甲方负责补偿丙方资金损失。<br/>
	      <p>第六条  争议解决</p>
	      本协议履行过程中，如果发生争议，可由三方协商解决，协商不成，任何一方有权向法院提起诉讼，由甲方电子商务服务受理银行住所地人民法院管辖。<br/>
	      在诉讼期间，本协议不涉及争议的条款仍需履行。<br/>
	      <p>第七条  协议终止</p>
	      1.丙方有权在线解除本协议。丙方解除本协议的，本协议终止，甲乙双方不再为丙方提供授权支付服务。<br/>
	      2.甲方和乙方协商一致拟终止业务合作关系的，应当由甲方提前十五个工作日在其网站上公告。公告期满，本协议自动终止，甲方和乙方将不再为丙方办理授权支付服务。
	    </div>
	  </div>
	)
}