import { randomNumber } from '@/utils/tool';
import { Dialog } from 'antd-mobile';

class Abc {

  init(deviceId: string) {
    const randomState = randomNumber(10);
    window.location.replace(`https://www.abchina.com/luascript/oauthLogin/{\"client_id\":\"c4f5c15e-d497-43cc-ad15-49caf443aa69\",
    \"redirect_uri\":\"https://api.iotzhihe.com/Api/cuser/V1/Member/AbcTokenMsg?deviceNum=${deviceId}\",\"state\":\"${randomState}\",\"scope\":\"openid\",\"response_type\":\"code
    \"}`);
  }

  scan() {
    Dialog.alert({
      content: '暂不支持，请使用农行扫一扫',
      confirmText: '知道了',
      onConfirm: () => {
        console.log('Confirmed')
      },
    })
  }

  setTitle(title: string) {
    document.title = title;
  }

  sign() {}

  closeWindow() {}

}


export default new Abc();