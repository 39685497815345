import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import AppStore, { persistor } from '@/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Router from '@/router/index';
import VConsole from 'vconsole';

let vConsole = null;
if(process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'pre') {
  vConsole = new VConsole();
}


type Root = Element | DocumentFragment;
const container: Root = document.getElementById('root') as Root;
const root = createRoot(container);
root.render(
  <Provider store={ AppStore }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
