const url = window.location.href;
const ua = window.navigator.userAgent.toLowerCase();
export default function userAgent() {
  let userAgent = '';
  if(url.includes('psbc')) {
    userAgent = 'psbc';
  } else if(ua.includes('bankabc/')) {
    userAgent = 'abc';
  } else if(ua.includes('alipayclient')) {
    userAgent = 'alipay';
  } else if(url.includes('/cmb/')) {
    userAgent = 'cmb';
  } else {
    userAgent = 'alipay';
  }
  return userAgent;
}