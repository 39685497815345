import { queryStringParse } from '@/utils/tool';

class Alipay {

  static appId = process.env.REACT_APP_ENV === 'production' ? '2021005103687363' : 
  ( process.env.REACT_APP_ENV === 'development' ? '2021005103687363' : '2021005103687363'); // 支付宝appId配置

  /**
   * @desc 初始化alipay环境
  */
  init(callback: any) {
    if ((window as any).AlipayJSBridge) {
      callback && callback()
    } else {
      document.addEventListener('AlipayJSBridgeReady', callback, false)
    }
  }

  /**
   * @desc 扫码返回设备id
  */
  scan () {
    return new Promise((resolve, reject) => {
      this.init(() => {
        (window as any).AlipayJSBridge.call('scan', {
          scanType: [ 'qrCode' ],
        }, (res: any) => {
          console.log(res)
          if (res.error) resolve(res.error);
          let deviceId = queryStringParse(res.qrCode)['deviceid']
          if (!deviceId) {
            deviceId = queryStringParse(res.qrCode)['sim']
          }
          resolve({ deviceId })
        })
      })
    })
  }

  /**
   * @desc 设置页面标题
  */
  setTitle(title: string) {
    this.init(() => {
      (window as any).AlipayJSBridge.call('setTitle', {
        title,
      })
    })
  }

  /**
   * @desc 授权
  */
  sign() {
    return new Promise((resolve, reject) => {
      (window as any).ap.getAuthCode({
        appId: Alipay.appId,
        scopes: [ 'auth_user' ],
        showErrorTip: true
      }, (res: any) => {
        console.log('auth', JSON.stringify(res))
        if(res.error) {
          resolve({ error: res.error })
        } else {
          resolve(res)
        }
      })
    })
  }

  /**
   * @desc 关闭当前页面
  */
  closeWindow () {
    this.init(() => {
      (window as any).AlipayJSBridge.call('exitApp')
    })
  }
}

const alipay = new Alipay()

export default alipay