let loadingStatus = {
  status: true
}

export default function showLoading(state = loadingStatus, action: any) {
  switch(action.type) {
    case 'SET_SHOW_LOADING':
      return { status: true }
    case 'SET_HIDE_LOADING':
      return { status: false }
    default:
      return state;
  }
}