let navTitle = {
  name: ''
}

export default function currentNavTitle(state = navTitle, action: any) {
  switch(action.type) {
    case 'SET_NAVTITLE':
      return { name: action.value }
    default:
      return state;
  }
}