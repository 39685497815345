import React from 'react';
import './App.less';
import { isGrayScalImage } from '@/utils/tool';
import Nav from '@/components/Nav/Nav';
import Loading from '@/components/Loading/Loading';
import SelectDoor from '@/components/SelectDoor/SelectDoor';
import { useSelector } from 'react-redux';

interface IProps {
  children: JSX.Element
}

const isGrayScale = isGrayScalImage()

export default function App(props: IProps) {

  let currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  let showLoading = useSelector((state: any) => state.showLoading.status);
  let selectDoorTypeShow = useSelector((state: any) => state.globalSelectDoor.selectDoorType);

  const showNavList = ['psbc'];

  return (
    <React.Fragment>
      <section style={{ display: showLoading ? 'block' : 'none' }}>
        <Loading/>
      </section>
      <section style={{ display: selectDoorTypeShow ? 'block' : 'none' }}>
        <SelectDoor/>
      </section>
      <section style={{ display: showNavList.includes(currentPlatform) ? 'block' : 'none' }}>
        <Nav />
      </section>
      <section className={ showNavList.includes(currentPlatform) ? 'short' : 'large' }>
        <section className={isGrayScale ? 'App filter-image' : 'App'}>
          {props.children}
        </section>
      </section>
    </React.Fragment>
  )
}
