import React, { useState, useEffect } from 'react';
import { TabBar, Dialog } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import platform from '@/platform';
import { SignConfig } from '@/type/cmbsign';
import './Tabbar.less';


export default function Tabbar() {
  let platformObject = useSelector((state: any) => state.currentPlatform.platform);
  let currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  let currentCmbUserId = useSelector((state: any) => state.currentPlatform.platform.cmbUserId);
  const tabs = [
    {
      key: 'home',
      title: '首页',
      show: []
    },
    // {
    //   key: 'code',
    //   title: '柜码开门',
    //   show: ['alipay', 'cmb']
    // },
    // {
    //   key: 'service',
    //   title: '客服',
    //   show: ['alipay', 'cmb']
    // },
    {
      key: 'user',
      title: '个人中心',
      show: []
    },
  ]
  /**
   * @desc 获取当前选中的tab
  **/
  const currentActiveKey = useSelector((state: any) => {
    return state.activeTabKey.name;
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [ activeKey, setActiveKey ] = useState(currentActiveKey); // 选中的tab key

  /**
   * @desc 判断招行是否登陆授权
  */
  const checkCmbLogin = () => {
    return new Promise(async (resolve, reject) => {
      const { code, data } = await platform.cmb.login() as any;
      if(code === 1) {
        const signConfig = await platform.cmb.signDialog(data) as SignConfig;
        console.log('signConfig', signConfig)
        if(signConfig.errorCode) {
          resolve('')
        }
        const { code: signCode, data: cmbUserId } = await platform.cmb.sign(signConfig) as { code: number, data: string };
        if(signCode === 1) {
          // 返回用户id
           resolve(cmbUserId);
        } else {
          // 没有授权
          resolve('')
        }
      } else {
        resolve('')
      }
    })
  }

  /**
   * @desc 切换tab
  **/
  const toggleTabbar = async (item: string) => {
    if(currentPlatform === 'cmb' && !currentCmbUserId) {
      const cmbUserId = await checkCmbLogin();
      if(cmbUserId) {
        dispatch({
          type: 'SET_PLATFORM',
          value: {
            cmbUserId,
            ...platformObject
          }
        })
      } else {
        return;
      }
    }
    // 点击客服
    if(item === 'service') {
      Dialog.confirm({
        title: '提示',
        content: <section className="Dialog-box">如有疑问，请联系客服或拨打电话 <a href="tel:029-86627864" className="tel" onClick={() => {
          Dialog.clear()
        }}>029-86627864</a></section>,
        closeOnMaskClick: false,
        confirmText: '联系客服',
        onConfirm: () => {
          const tel = document.querySelector('.tel') as any;
          tel.click()
        },
      })
      return;
    }
    if(activeKey !== item) {
      // 修改当前选中
      dispatch({
        type: 'SET_ACTIVE',
        value: item
      })
      navigate(`/${item}`, { replace: true })
      setActiveKey(item);
    }
  }

  /**
   * @desc 根据当前路由设置选中tab
  */
  const setActiveTab = (activeKey: string) => {
    dispatch({
      type: 'SET_ACTIVE',
      value: activeKey
    })
    setActiveKey(activeKey)
  }

  /**
   * @desc 获取路由地址
  */
  const getLocationPath = () => {
    const url = window.location.hash;
    const name = url && url.split('/')[1] && !url.split('/')[1].includes('?') && url.split('/')[1].length < 8 ? url.split('/')[1] : 'home';
    setActiveTab(name)
  }

  useEffect(() => {
    getLocationPath()
  }, [currentActiveKey, currentPlatform, activeKey])

  return (
    <section className="tabbar-box">
      <TabBar safeArea activeKey={activeKey} onChange={toggleTabbar}>
        { tabs.map(item => {
          if(!item.show.length) {
            let iconImg = item.key === activeKey ? `${item.key}-active` : item.key
            return (
              <TabBar.Item key={item.key} icon={
                <div
                  style={{
                    width: '26px',
                    height: '26px',
                    background: `url(../../assets/${iconImg}.png) no-repeat center center`,
                  }}
                />
              } title={item.title} />
            )} else {
              return ''
            }
          }
        )}
      </TabBar>
    </section>
  )
}