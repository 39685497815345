let globalUser = {
  userInfo: {}
}

export default function userInfo(state = globalUser, action: any) {
  switch(action.type) {
    case 'SET_USERINFO':
      return {
        userInfo: action.value
      }
    default:
      return state;
  }
}