import axios from './axios';
import qs from 'qs';

type ResponseType = {
  msg?: string;
  errorMessage?: any;
  code: number,
  data?: any,
  state?: number,
  message?: string,
	status?: number
}


interface Config {
  url: string
  data?: object,
  isLoading?: boolean
}

type LogConfig = {
  data: object
}

class http {

  get(config: Config) : Promise<ResponseType> {
    const { url, data = {} } = config;
    return new Promise((resolve, reject) => {
      axios.get(url, { data: qs.stringify(data) }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  post(config: Config) : Promise<ResponseType> {
    const { url, data } = config;
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  log(config: LogConfig): Promise<ResponseType> {
    const url = 'https://log.longguest.com/api/appendLog';
    const { data } = config;
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => {
        resolve(res as any)
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default new http();
