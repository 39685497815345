import React, { useState, useEffect } from 'react';
import { Toast, Button, Checkbox } from 'antd-mobile';
import http from '@/http/http';
import { useNavigate } from 'react-router-dom';
import { Input, NumberKeyboard, hooks } from 'react-vant';
import { useDispatch, useSelector } from 'react-redux';
import { checkSign, appendFrontendLog } from '@/utils/common';
import './userLogin.less';
import platform from '@/platform';
import { setLocal } from '@/utils/storage';
import userAgent from '@/utils/agent';
import { scanType } from '@/constant/scanType';

export default function UserLogin() {
  let [ phone, setPhone ] = hooks.useSetState({ visible: false, value: '' });
  let [ code, setCode ] = hooks.useSetState({ visible: false, value: '' });
  let [ protocolChecked, setChecked ] = useState(true);
  let [ sendCodeText, setCodeText ] = useState('获取验证码');
  let [ isConfirmDisabled, setConfirmBtn ] = useState(true);
  let [ disableSendBtn, setDisableSendBtn ] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform)
  const queryInfo = useSelector((state: any) => state.currentPlatform.platform);

  /**
   * @desc 检测手机号是否合法
  */
  const checkPhoneValid = () => {
    const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
    let isVaild = true;
    if(!reg.test(phone.value)) {
      Toast.show({
        icon: 'fail',
        content: '手机号输入错误',
      })
      isVaild = false;
    }
    return isVaild;
  }

  /**
   * @desc 点击获取验证码方法
  */
  const sendPhoneCode = () => {
    if(checkPhoneValid() && sendCodeText === '获取验证码') {
      dispatch({
        type: 'SET_SHOW_LOADING'
      })
      setDisableSendBtn(true)
      getPhoneCode();
    }
  }

  /**
   * @desc 调用获取验证码方法
  */
  const getPhoneCode = () => {
    http.get({
      url: `/api/cuser/v1/member/VerificationCode?phone=${phone.value}&platform=${currentPlatform}`,
      data: {}
    }).then(res => {
      appendFrontendLog({
        label: 'getPhoneCode',
        content: JSON.stringify({ phone: phone.value, res })
      })
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      if(res.code === 1) {
        formatCodeText()
        Toast.show({
          icon: 'success',
          content: '验证码发送成功',
        })
      } else {
        setDisableSendBtn(false)
        Toast.show({
          icon: 'fail',
          content: '验证码发送失败',
        })
      }
    }).catch(error => {
      setDisableSendBtn(false)
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: '验证码发送失败',
      })
    })
  }

  /**
   * @desc 获取验证码倒计时
  */
  const formatCodeText = () => {
    let countTime = 60;
    let timer = setInterval(() => {
      if(countTime <= 1) {
        clearInterval(timer);
        setCodeText('获取验证码')
        setDisableSendBtn(false)
      } else {
        countTime -= 1;
        setCodeText(`${countTime.toString().padStart(2, '0')}s后重试`)
      }
    }, 1000)
  }

  /**
   * @desc 点击确认方法
  */
  const confirmUserInfo = async () => {
    if(!checkPhoneValid()) return;
    if(code.value.length !== 6) { // 验证码输入错误
      Toast.show({
        icon: 'fail',
        content: '验证码输入错误',
      })
    } else {
      if(currentPlatform === 'alipay') {
        let { authCode } = await platform.alipay.sign() as { authCode?: string };
        if(authCode) {
          ResolveConfirm(authCode)
        } else {
          Toast.show({
            icon: 'fail',
            content: '授权失败',
          })
        }
      } else {
        ResolveConfirm('')
      }

    }
  }

  /**
   * @desc 提交信息方法
  */
  const sendUserInfo = (authCode: string) => {
    return new Promise((resolve, reject) => {
      http.post({
        url: '/api/cuser/v1/member/H5BinPhone',
        data: {
          phone: phone.value,
          code: code.value,
          authCode,
          platform: queryInfo.platform,
          memberSource: queryInfo.memberSource,
          customerId: queryInfo.customerId,
          AbcUserId: queryInfo.AbcUserId,
          cmbUserId: queryInfo.cmbUserId
        }
      }).then(res => {
        appendFrontendLog({
          label: 'sendUserInfo',
          content: JSON.stringify({ parames: {
            phone: phone.value,
            code: code.value,
            authCode,
            platform: queryInfo.platform,
            memberSource: queryInfo.memberSource,
            customerId: queryInfo.customerId,
            AbcUserId: queryInfo.AbcUserId,
            cmbUserId: queryInfo.cmbUserId
          }, res })
        })
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
  /**
   * @desc 获取环境，设置platform
  */
  function resolvePlatform() {
    let ua = userAgent();
    let uaValue = 0;
    switch(ua) {
      case 'alipay':
        uaValue = scanType.ALIPAY;
        break;
      case 'psbc':
        uaValue = scanType.PSBC;
        break;
      case 'abc':
        uaValue = scanType.ABC;
        break;
      case 'cmb':
        uaValue = scanType.CMB;
        break;
      default:
        uaValue = scanType.ALIPAY;
        break;
    }
    dispatch({
      type: 'SET_PLATFORM',
      value: {
        platform: ua || 'alipay',
        memberSource: uaValue,
        signedDigitalCurrency: true,
        ...queryInfo
      }
    })
  }

  /**
   * @desc 登录后处理逻辑
  */
  const ResolveConfirm = async (authCode: string) => {
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    let { code, data, message } = await sendUserInfo(authCode) as any;
    if(code === 1) {
      setLocal({ key: 'token', value: data.token });
      setLocal({ key: 'userInfo', value: data.memberInfo })
      dispatch({
        type: 'SET_USERINFO',
        value: data.memberInfo
      })
      dispatch({
        type: 'SET_TOKEN',
        value: data.token
      })
      let { code } = await checkSign(currentPlatform) as any;
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      if(code === 1) { // 已经签约
        if(currentPlatform === 'alipay' || currentPlatform === 'cmb') {
          if(window.history.length > 1) { // 返回首页
            navigate(-1)
          } else {
            navigate('/home', { replace: true })
          }
        } else {
          navigate('/purchas', { replace: true })
        }
      } else {
        if(currentPlatform === 'alipay') { // 跳转到协议
          navigate('/protocol', { replace: true })
        } else if(currentPlatform === 'cmb') { // 招行签约
          const { code: signCode, data: signUrl, message: signMsg } = await platform.cmb.cardSign(data.memberInfo) as { code: number, data: string, message: string }
          if(signCode === 1 && signUrl) {
            if(window.history.length > 1) { // 返回首页
              navigate(-1)
            } else {
              navigate('/home', { replace: true })
            }
            window.location.href = signUrl;
          } else {
            Toast.show({
              icon: 'fail',
              content: signMsg || '授权失败',
            })
          }
        } else {
          navigate(`/${currentPlatform}/sign`, { replace: true , state: { active: 1, isOpen: true }}) // 跳转到协议
        }
      }
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: message || data.message || data.msg,
      })
    }
  }

  /**
   * @desc 查看用户协议
  */
  const protocal = () => {
    window.location.href = 'http://cdn.custom.weilaixiansen.com/protocol.html'
  }

  /**
   * @desc 用户协议选中状态变化
  */
  const checkboxChange = () => {
    let checked = !protocolChecked
    setChecked(checked)
  }

  useEffect(() => {
    if(phone.value.length === 11) {
      setDisableSendBtn(false)
    } else {
      setDisableSendBtn(true)
    }
  }, [ phone.value ])

  useEffect(() => {
    if(phone.value.length === 11 && code.value.length === 6 && protocolChecked){
      setConfirmBtn(false)
    } else {
      setConfirmBtn(true)
    }
  }, [phone.value, code.value, protocolChecked, currentPlatform])

  useEffect(() => {
    resolvePlatform();
    
    if(currentPlatform === 'abc') {
      currentPlatform && platform[currentPlatform as keyof typeof platform].setTitle('绑定手机号码');
      dispatch({
        type: 'SET_NAVTITLE',
        value: '绑定手机号码'
      })
    } else {
      currentPlatform && platform[currentPlatform as keyof typeof platform].setTitle('用户登录');
      dispatch({
        type: 'SET_NAVTITLE',
        value: '用户登录'
      })
    }
    dispatch({
      type: 'SET_HIDE_LOADING'
    })
  }, [])

  return (
    <section className="userLogin">
      <section className="logo"></section>
      <section className="info">
        <Input
          placeholder="请输入手机号"
          type="tel"
          maxLength={11}
          onChange={(value) => setPhone({value})}
          value={phone.value}
        />
        {/* <NumberKeyboard
          safeAreaInsetBottom
          value={phone.value}
          maxlength={11}
          visible={phone.visible}
          onChange={(value) => {
            setPhone({value})
          }}
          onBlur={() => {
            setPhone({ visible: false });
          }}
        /> */}
        <section className="code-box">
          <Input
            className="code-text"
            placeholder="请输入验证码"
            type="number"
            maxLength={6}
            onChange={(value) => {setCode({value})}}
            value={code.value}
          />
          <div className={ disableSendBtn ? "disabled-send-code-text" : "send-code-text" } onClick={sendPhoneCode}>
            <span>{sendCodeText}</span>
          </div>
        </section>
        {/* <NumberKeyboard
          safeAreaInsetBottom
          visible={code.visible}
          maxlength={6}
          value={code.value}
          onChange={(value) => {
            setCode({value})
          }}
          onBlur={() => {
            setCode({ visible: false });
          }}
        /> */}
        { currentPlatform !== 'alipay' ?
          <section className="protocol">
            <Checkbox checked={protocolChecked} onChange={checkboxChange}/>
            <p className="protocol-text">
              <span>请同意</span>
              <span className="pro-content" onClick={protocal}>《用户使用协议》</span>
            </p>
          </section>
          : ''
        }
        <Button block className="confirm-btn" size="large" disabled={isConfirmDisabled} onClick={confirmUserInfo}>
         登录
        </Button>
        {
          currentPlatform === 'abc' ? 
          <p className="tips">温馨提示: 未注册用户,首次使用时即完成注册。</p> :
          <p className="tips">温馨提示: 未注册用户,首次登录时即完成注册。</p>
        }
        
      </section>
    </section>
  )
}