import React from 'react';
import { Skeleton } from 'antd-mobile';
import './SkeleDetail.less';

export default function Skele() {
  return (
    <React.Fragment>
      <Skeleton.Title animated />
      <Skeleton animated className="customSkeleton" />
      <Skeleton.Title animated />
      <Skeleton animated className="customSkeleton" />
      <Skeleton.Title animated />
      <Skeleton animated className="customSkeleton" />
      <Skeleton.Title animated />
    </React.Fragment>
  )
}