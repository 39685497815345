let globalPlatform = {
  platform: {
   
  }
}

export default function currentPlatform(state = globalPlatform, action: any) {
  console.log('reducer', action)
  switch(action.type) {
    case 'SET_PLATFORM':
      return { platform: action.value }
    default:
      return state;
  }
}