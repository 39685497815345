import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'antd-mobile';
import platform from '@/platform';
import { useSelector, useDispatch } from 'react-redux';
import './happy.less';
import abc1 from '@/assets/abc_01.jpg';
import abc2 from '@/assets/abc_02.jpg';

export default function Happy() {
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [ showRulesBox, setRulesBox ] = useState(false);
  const goPay = () => {
    navigate('/user', { replace: true })
  }
  const fullYear = new Date().getFullYear();
  const showRule = () => {
    setRulesBox(true)
  }

  const hideRule = () => {
    setRulesBox(false)
  }

  useEffect(() => {
    if(!currentPlatform) {
      dispatch({
        type: 'SET_PLATFORM',
        value: {
          platform: 'abc',
          memberSource: 6,
        }
      })
    }
    (platform[currentPlatform as keyof typeof platform] || platform.abc).setTitle('欢乐购');
    dispatch({
      type: 'SET_NAVTITLE',
      value: '欢乐购'
    });
    dispatch({
      type: 'SET_HIDE_LOADING'
    });
    Dialog.alert({
      content: '该服务由第三方(西安岩心智能科技有限公司)提供，相关服务和责任由第三方承担，如有疑问请致电第三方服务电话: 029-86627864',
      onConfirm: () => {
        console.log('Confirmed')
      },
    })
  }, [])

  return (
    <section className="Abc-happy">
      <img src={abc1} alt="" />
      <img src={abc2} alt="" />
      <section className="gopay-box">
        <section className="gopay" onClick={goPay}>
        </section>
      </section>
      <section className="rules" onClick={showRule}></section>
      <section className="rules-box" style={{ display: showRulesBox ? 'block' : 'none' }}>
        <section className="rules-content">
          <section className="title">参与方式</section>
          <p className="content">填写手机号、姓名、身份证号、银行卡号完成免密签约操作,即可参与本活动。</p>
          <section className="title">活动内容</section>
          <p className="content">活动期间,农行掌银APP客户在智能售货机使用农行掌银扫码购物,即可享受五折优惠,优惠金额11.11元封顶！</p>
          <section className="title">活动规则</section>
          <p className="content">{fullYear}年1月1日至{fullYear}年12月31日（活动名额有限先到先得）,单个用户每月最多可享2次优惠。</p>
          <section className="title">客服电话</section>
          <p className="content">如有疑问,请致电智能售货机客服电话: <a href="tel:029-86627864">029-86627864</a></p>
          <section className="close-rules" onClick={hideRule}></section>
        </section>
      </section>
    </section>
  )
}