import React, { useState, useEffect } from 'react';
import http from '@/http/http';
import { Toast, Checkbox } from 'antd-mobile';
import { Field, NumberKeyboard, hooks, Dialog, Input } from 'react-vant';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import platform from '@/platform';
import { appendFrontendLog } from '@/utils/common';
import { checkPhoneValid, checkCertificateValid } from '@/utils/tool';
import './sign.less';


export default function Sign() {
  const { state } = useLocation();
  let [ activeSign, setActiveSign ] = useState((state as {active: number, isOpen: boolean}).active || 0); // 选中签约方式
  let [ isOpen ] =  useState((state as {active: number, isOpen: boolean}).isOpen || false); // 签约完成是否提示开门
  let [ accName, setAccName ] = useState('');
  let [ certificateNo, setCertificate] = hooks.useSetState({ visible: false, value: '' });
  let [ mobileNo, setPhone ] = hooks.useSetState({ visible: false, value: '' });
  let [ cardNo, setCardNo ] = hooks.useSetState({ visible: false, value: '' });
  let [ code, setCode ] = hooks.useSetState({ visible: false, value: '' });
  let [ sendCodeText, setCodeText ] = useState('获取验证码');
  let [ sendCodeDisabled, setSendCodeDisabled ] = useState(true); // 发送验证码按钮控制可点击
  let [ confirmBtnDisabled, setConfirmBtnDisabled ] = useState(true); // 提交信息按钮控制可点击
  // 签约接口返回
  let [ orderNo, setOrderNo ] = useState('');
  let [ serialNO, setSerialNo] = useState('');
  let [ applyID, setApplyID] = useState('');


  let [ protocolChecked, setChecked ] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform)
  /**
   * @desc 切换签约方式tab
  */
  const changeTabHandle = (value: number) => {
    setActiveSign(value)
  }

  /**
   * @desc 获取验证码倒计时
  */
  const formatCodeText = () => {
    let countTime = 60;
    let timer = setInterval(() => {
      if(countTime <= 1) {
        clearInterval(timer);
        setSendCodeDisabled(false)
        setCodeText('获取验证码')
      } else {
        countTime -= 1;
        setCodeText(`${countTime.toString().padStart(2, '0')}s后重试`)
      }
    }, 1000)
  }

  /**
   * @desc 调用获取验证码方法
  */
  const getPhoneCode = () => {
    let options = {};
    let url = '';
    if(activeSign) {
      url = '/api/cuser/V1/Member/AbcDwSignApply';
      options = {
        CustomerName: accName,
        CertificateNo: certificateNo.value,
        MobileNo: mobileNo.value
      }
    } else {
      url = '/api/cuser/V1/Member/AbcSignApply';
      options = {
        cardNo: cardNo.value,
        mobileNo: mobileNo.value,
        certificateType: 'I',
        certificateNo: certificateNo.value,
        accName,
      }
    }
    http.post({
      url,
      data: {
        ...options
      }
    }).then(res => {
      appendFrontendLog({
        label: 'SignSendCode',
        content: JSON.stringify({ options, res })
      })
      if(res.code === 1) {
        if(activeSign) {
          setSerialNo(res.data.serialNo);
          setApplyID(res.data.applyID);
        } else {
          setOrderNo(res.data.orderNo)
        }
        formatCodeText()
        Toast.show({
          icon: 'success',
          content: '验证码发送成功',
        })
      } else {
        setSendCodeDisabled(false)
        Toast.show({
          icon: 'fail',
          content: res.message || res.data.message || res.errorMessage || '验证码发送失败',
        })
      }
    }).catch(error => {
      setSendCodeDisabled(false)
      Toast.show({
        icon: 'fail',
        content: '验证码发送失败',
      })
    })
  }

  /**
   * @desc 用户协议选中状态变化
  */
  const checkboxChange = () => {
    let checked = !protocolChecked
    setChecked(checked)
  }

  /**
   * @desc 查看用户协议
  */
  const protocal = () => {
    navigate('/abc/agreement')
  }

  /**
   * @desc 点击获取验证码方法
  */
  const sendPhoneCode = () => {
    if(sendCodeDisabled) return;
    if(!accName) {
      Toast.show({
        icon: 'fail',
        content: '请输入姓名',
      })
      return;
    }
    if(!cardNo.value && !activeSign) {
      Toast.show({
        icon: 'fail',
        content: '请输入银行卡号',
      })
      return;
    }
    if(checkCertificateValid(certificateNo.value) &&
      checkPhoneValid(mobileNo.value) && !sendCodeDisabled) {
      setSendCodeDisabled(true)
      getPhoneCode();
    }
  }

  /**
   * @desc 提交签约接口
  */
  const signConfirm = () => {
    let url = '';
    let options = {};
    if(activeSign) {
      url = '/api/cuser/V1/Member/AbcDwSignConfirm'
      options = {
        MobileNo: mobileNo.value,
        VerifyCode: code.value,
        ApplyID: applyID,
        SerialNO: serialNO
      };
    } else {
      url = `/api/cuser/V1/Member/AbcSignConfirm`;
      options = { orderNo, verifyCode: code.value }
    }
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data: { ...options }
      }).then(res => {
        resolve(res)
      }).catch(error => {
        Toast.show({
          icon: 'fail',
          content: '签约信息提交失败',
        })
      })
    })
  }
  /**
   * @desc 提交签约信息
  */
  const submitSignInfo = async () => {
    if(confirmBtnDisabled) return;
    if(!accName) {
      Toast.show({
        icon: 'fail',
        content: '请输入姓名',
      })
      return;
    }
    if(!cardNo.value && !activeSign) {
      Toast.show({
        icon: 'fail',
        content: '请输入银行卡号',
      })
      return;
    }
    if(!code.value || code.value.length !== 6) {
      Toast.show({
        icon: 'fail',
        content: '请输入正确的验证码',
      })
      return;
    }
    if(!protocolChecked) {
      Toast.show({
        icon: 'fail',
        content: '请先同意免密代扣协议',
      })
      return;
    }
    if(checkCertificateValid(certificateNo.value) &&
    checkPhoneValid(mobileNo.value) && !confirmBtnDisabled) {
      setConfirmBtnDisabled(true)
      dispatch({
        type: 'SET_SHOW_LOADING'
      })
      const { code: resCode, data, message } = await signConfirm() as any;
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      if(resCode === 1) {
        setConfirmBtnDisabled(false)
        if(isOpen) {
          Dialog.confirm({
            message: '签约成功，确定是否开门',
          })
          .then(() => {
            navigate('/purchas', { replace: true })
          })
          .catch(() => {
            if(window.history.length > 1) {
              navigate(-1)
            } else {
              navigate('/home', { replace: true })
            }
          })
        } else {
          Toast.show({
            icon: 'success',
            content: '签约成功',
            duration: 1000
          })
          setTimeout(() => {
            navigate(-1)
          }, 1500)
        }
      } else {
        setConfirmBtnDisabled(false)
        Toast.show({
          icon: 'fail',
          content: message || data.message || '签约失败',
        })
      }
      appendFrontendLog({
        label: 'SignConfirm',
        content: JSON.stringify({ code: resCode, message })
      })
    }
  }

 /**
   * @desc 控制发送验证码按钮
  */
  useEffect(() => {
    if(!activeSign) {
      if(accName && certificateNo.value && cardNo.value && mobileNo.value ) {
        setSendCodeDisabled(false)
      } else {
        setSendCodeDisabled(true)
      }
    } else {
      if(accName && certificateNo.value && mobileNo.value) {
        setSendCodeDisabled(false)
      } else {
        setSendCodeDisabled(true)
      }
    }
  }, [ accName, certificateNo, cardNo, mobileNo, activeSign ])

  /**
   * @desc 控制提交信息按钮
  */
  useEffect(() => {
    if(!activeSign) {
      if(accName && certificateNo.value && code.value && cardNo.value && mobileNo.value && protocolChecked) {
        setConfirmBtnDisabled(false)
      } else {
        setConfirmBtnDisabled(true)
      }
    } else {
      if(accName && certificateNo.value && code.value && mobileNo.value && protocolChecked) {
        setConfirmBtnDisabled(false)
      } else {
        setConfirmBtnDisabled(true)
      }
    }
  }, [ accName, certificateNo, code, cardNo, protocolChecked, activeSign ])

  useEffect(() => {
    platform[currentPlatform as keyof typeof platform].setTitle('用户签约');
    dispatch({
      type: 'SET_NAVTITLE',
      value: '用户签约'
    })
  }, [currentPlatform])

  return (
    <section className="Sign">
      <ul className="sign-tab">
        <li className={activeSign === 0 ? 'sign-tab-item active' : 'sign-tab-item'} onClick={() => { changeTabHandle(0) }}>农行免密签约</li>
        <li className={activeSign === 1 ? 'sign-tab-item active' : 'sign-tab-item'} onClick={() => { changeTabHandle(1) }}>数字货币免密签约</li>
      </ul>
      <section className="sign-box">
        <Field
          label="姓名"
          placeholder="请输入姓名"
          value={accName}
          name="accName"
          clearable
          onChange={setAccName}
        />
        <Field
          label="身份证号"
          placeholder="请输入身份证号"
          name="certificateNo"
          readOnly
          value={certificateNo.value}
          onClick={() => {setCertificate({ visible: true })}}
        />
        <NumberKeyboard
          safeAreaInsetBottom
          value={certificateNo.value}
          extraKey="X"
          maxlength={18}
          closeButtonText="完成"
          visible={certificateNo.visible}
          onChange={(value) => {
            setCertificate({value})
          }}
          onBlur={() => {
            setCertificate({ visible: false });
          }}
        />
        {
          activeSign === 0 ?
          <Field
            label="银行卡号"
            type="number"
            placeholder="请输入银行卡号"
            value={cardNo.value}
            onChange={(value) => {setCardNo({value})}}
          /> : ''
        }
        <Field
          label="手机号"
          placeholder="请输入手机号"
          maxLength={11}
          type="tel"
          value={mobileNo.value}
          onChange={(value) => {setPhone({value})}}
        />
        <section className="code-box">
          <Field
            label="验证码"
            className="code-text"
            placeholder="请输入验证码" 
            maxLength={6}
            type="number"
            value={code.value}
            onChange={(value) => {setCode({value})}}
          />
          <div className={ sendCodeDisabled ? "disabled-send-code-text" : "send-code-text" } onClick={sendPhoneCode}>
            <span>{sendCodeText}</span>
          </div>
        </section>
        <section className="protocol">
          <Checkbox checked={protocolChecked} onChange={checkboxChange}/>
          <p className="protocol-text">
            <span>免密签约后方可消费，请同意</span>
            <span className="pro-content" onClick={protocal}>《免密代扣协议》</span>
          </p>
        </section>
        <section className={ confirmBtnDisabled ? "disabled-confirm-btn" : "confirm-btn" } onClick={submitSignInfo}>确定</section>
      </section>
    </section>
  )
}