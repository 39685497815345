import React, { useState, useEffect } from 'react';
import Tabbar from '@/components/Tabbar/Tabbar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLocal, setLocal } from '@/utils/storage';
import { Dialog, Toast } from 'react-vant';
import { Dialog as VanDialog } from 'antd-mobile';
import platform from '@/platform';
import http from '@/http/http';
import './User.less';
import { authCheck, checkUserRegistry, getTokenAndUserInfo, appendFrontendLog } from '@/utils/common';
import userAgent from '@/utils/agent';
import { scanType } from '@/constant/scanType';

type Entrance = {
  icon: string
  title: string
  path: string
  [propname: string]: any
}

export default function User() {
  const entrance: Entrance[] = [
    {
      icon: 'order',
      title: '我的订单',
      path: 'orderList',
      show: [],
    },
    // {
    //   icon: 'order',
    //   title: '补扣订单',
    //   path: 'supplyOrderList',
    //   show: [],
    // },
    {
      icon: 'question',
      title: '常见问题',
      path: 'question',
      show: [],
    },
    {
      icon: 'bank',
      title: '农行储蓄卡免密支付',
      path: 'abc/sign',
      show: ['abc'],
    },
    {
      icon: 'digital',
      title: '农行数字货币免密支付',
      path: 'abc/sign',
      show: ['abc'],
    },
    {
      icon: 'bank',
      title: '邮储银行储蓄卡免密支付',
      path: 'psbc/sign',
      show: ['psbc'],
    },
    {
      icon: 'digital',
      title: '邮储数字货币免密支付',
      path: 'psbc/sign',
      show: ['psbc'],
    },
    {
      icon: 'bank',
      title: '招行免密支付',
      path: '',
      show: ['cmb'],
    },
    {
      icon: 'service',
      title: '客服',
      path: 'service',
      show: [],
    },
    {
      icon: 'about',
      title: '关于我们',
      path: 'aboutUs',
      show: [],
    }
  ]
  // store中用户信息
  const globalUserInfo = useSelector((state: any) => {
    return state.userInfo.userInfo;
  })
  const dispatch = useDispatch();
  let [ isBankSign, setBankSign ] = useState(false);
  let [ isDigitalSign, setDigitalSign ] = useState(false);

  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  const platformObject = useSelector((state: any) => state.currentPlatform.platform)
  const userInfoStorage = getLocal('userInfo')
  let userInfo = Object.assign({}, globalUserInfo, userInfoStorage);

  const navigate = useNavigate();

  const NavigatePath = async (path: string) => {
    navigate(`/${path}`)
  }

  const formatPhone = (phone: string) => {
    return `${phone.slice(0, 3)}****${phone.slice(-4)}`;
  }

  const userItemHandle = (item: any) => {
    if(item.path === 'service') {
      VanDialog.confirm({
        title: '提示',
        content: <section className="Dialog-box">如有疑问，请联系客服或拨打电话 <a href="tel:029-86627864" className="tel" onClick={() => {
          VanDialog.clear()
        }}>029-86627864</a></section>,
        closeOnMaskClick: false,
        confirmText: '联系客服',
        onConfirm: () => {
          const tel = document.querySelector('.tel') as any;
          tel.click()
        },
      })
      return;
    }
    if(!item.show.length) {
      NavigatePath(item.path)
    }
  }

  /**
   * @desc 获取银行卡签约状态
  */
  const getBankSignStatus = () => {
    let url = '';
    switch(currentPlatform) {
      case 'abc':
        url = '/api/cuser/V1/Member/IsAbcFreepaySign';
        break;
      case 'psbc':
        url = '/api/cuser/V1/Member/IsPsbcFreepaySign';
        break;
      case 'cmb':
        url = '/api/cuser/V1/Member/IsCmbFreepaySign';
        break;
      default:
        break;
    }
    const data = { phone: userInfo.mobile };
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 获取数字货币签约状态
  */
  const getDigitalSignStatus = () => {
    let url = '';
    switch(currentPlatform) {
      case 'abc':
        url = '/api/cuser/V1/Member/IsAbcDigitalWalletSign';
        break;
      case 'psbc':
        url = '/api/cuser/V1/Member/IsPsbcDigitalWalletSign'
        break;
      default:
        break;
    }
    const data = { phone: userInfo.mobile } 
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
  /**
   * @desc 农行用户自动获取token
  */
  const automicTokenForAbc = (callback?: Function) => {
    if(platformObject.platform === 'abc') {
      let abcUserId = platformObject.AbcUserId ?? platformObject.abcUserId;
      if(abcUserId) {
        checkUserRegistry(abcUserId).then((res: any) => {
          appendFrontendLog({
            label: 'user checkUserRegistry',
            content: JSON.stringify({ abcUserId, res })
          })
          if(res.code === 1 && res.data) { // 新用户
            navigate('/userLogin', { replace: true })
          } else {
            getTokenAndUserInfo(abcUserId).then((result: any) => {
              setLocal({ key: 'token', value: result.data.token });
              setLocal({ key: 'userInfo', value: result.data.memberInfo });
              dispatch({
                type: 'SET_TOKEN',
                value: result.data.token
              });
              dispatch({
                type: 'SET_USERINFO',
                value: result.data.memberInfo
              });
              appendFrontendLog({
                label: 'user getTokenAndUserInfo',
                content: JSON.stringify({ abcUserId, result })
              })
              callback && callback()
            })
          }
        })
      } else {
        platform.abc.init('');
      }
    } else {
      navigate('/userLogin', { replace: true })
    }
  }
  /**
   * @desc 解析签约信息
  */
  const resolveSignStatus = async () => {
    const { code, data } = await getBankSignStatus() as { code: number, data: any }
    if(code === 1) {
      setBankSign(data);
      if(currentPlatform === 'cmb') {
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
      } else {
        const { code: digitalCode, data: digitalData } = await getDigitalSignStatus() as { code: number, data: any }
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        if(digitalCode === 1) {
          setDigitalSign(digitalData);
        } else if(digitalCode === -100) {
          automicTokenForAbc(resolveSignStatus)
        }
      }
    } else if(code === -100) {
      automicTokenForAbc(resolveSignStatus)
    }
  }

  /**
   * @desc 银行卡签约信息
  */
  const bankSignToggle = () => {
    if(currentPlatform === 'psbc' && isBankSign) {
      Toast.fail('暂未开通解约服务');
      return;
    }
    Dialog.confirm({
      title: '提示',
      confirmButtonColor: '#07c160',
      message: isBankSign ? '是否关闭免密支付' : '是否开通免密支付',
    })
    .then(async () => {
      console.log('confirm');
      if(isBankSign) {
        dispatch({
          type: 'SET_SHOW_LOADING'
        })
        const {code, data, message} = await unSignBankCard() as { code: number, data: any, message: string };
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        if(code === 1) {
          Toast.success('解约成功')
          setBankSign(false)
        } else {
          Toast.fail(message || '解约失败')
        }
      } else {
        signBankCard();
      }
    })
    .catch(() => {
      console.log('catch');
    })
  }

  /**
   * @desc 数字货币签约信息
  */
  const digitalSignToggle = () => {
    Dialog.confirm({
      title: '提示',
      confirmButtonColor: '#07c160',
      message: isDigitalSign ? '是否关闭数字货币免密支付' : '是否开通数字货币免密支付',
    })
    .then(async () => {
      console.log('confirm');
      if(isDigitalSign) {
        dispatch({
          type: 'SET_SHOW_LOADING'
        })
        const { code, data, message } = await unSignDigital() as { code: number, data: any, message: string };
        dispatch({
          type: 'SET_HIDE_LOADING'
        })
        if(code === 1) {
          Toast.success('解约成功')
          setDigitalSign(false)
        } else {
          Toast.fail(message || '解约失败')
        }
      } else {
        signDigital();
      }
    })
    .catch(() => {
      console.log('catch');
    })
  }

  /**
   * @desc 开通银行卡免密支付
  */
  const signBankCard = async () => {
    const userAuth = await authCheck();
    if(userAuth) {
      if(currentPlatform === 'cmb') {
        cmbSign()
      } else {
        navigate(`/${currentPlatform}/sign`, { state: { active: 0, isOpen: false } })
      }
    } else {
      automicTokenForAbc(signBankCard)
    }
  }

  /**
   * @desc 招行签约
  */
  const cmbSign = async () => {
    const { code: signCode, data: signUrl, message: signMsg } = await platform.cmb.cardSign(userInfo) as { code: number, data: string, message: string }
    if(signCode === 1 && signUrl) {
      window.location.href = signUrl;
    } else {
      Toast.fail(signMsg || '授权失败')
    }
  }

  /**
   * @desc 关闭银行卡免密支付
  */
  const unSignBankCard = () => {
    let url = '';
    switch(currentPlatform) {
      case 'abc':
        url = '/api/cuser/V1/Member/AbcFreepayUnsign';
        break;
      case 'cmb':
        url = '/api/cuser/V1/Member/CmbFreepayUnsign'
      default:
        break;
    }
    const data = { phone: userInfo.mobile } 
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 开通数字货币免密支付
  */
  const signDigital = () => {
    navigate(`/${currentPlatform}/sign`, { state: { active: 1, isOpen: false } })
  }

  /**
   * @desc 关闭数字货币免密支付
  */
  const unSignDigital = () => {
    let url = '';
    switch(currentPlatform) {
      case 'abc':
        url = '/api/cuser/V1/Member/AbcDigitalWalletUnsign';
        break;
      case 'psbc':
        url = '/api/cuser/V1/Member/PsbcDigitalWalletCancelSign';
        break;
      default:
        break;
    }
    const data = { phone: userInfo.mobile };
    return new Promise((resolve, reject) => {
      http.post({
        url,
        data
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

  /**
   * @desc 从其他地方进入个人中心，设置UA
  */
  const resolvePlatform = () => {
    let ua = userAgent();
    let uaValue = 0;
    switch(ua) {
      case 'alipay':
        uaValue = scanType.ALIPAY;
        break;
      case 'psbc':
        uaValue = scanType.PSBC;
        break;
      case 'abc':
        uaValue = scanType.ABC;
        break;
      case 'cmb':
        uaValue = scanType.CMB;
        break;
      default:
        uaValue = scanType.ALIPAY;
        break;
    }
    dispatch({
      type: 'SET_PLATFORM',
      value: {
        platform: ua || 'abc',
        memberSource: uaValue,
        signedDigitalCurrency: true
      }
    })
  }

  useEffect(() => {
    if(!currentPlatform) {
      resolvePlatform()
    }
    if(currentPlatform === 'abc') {
      automicTokenForAbc(resolveSignStatus)
    } else {
      document.addEventListener('visibilitychange', () => {
        if(!document.hidden) {
          resolveSignStatus()
        }
      })
      currentPlatform && platform[currentPlatform as keyof typeof platform].setTitle('个人中心');
      dispatch({
        type: 'SET_NAVTITLE',
        value: '个人中心'
      })
  
      if(currentPlatform !== 'alipay') {
        dispatch({
          type: 'SET_SHOW_LOADING'
        })
        resolveSignStatus()
      }
    }
  }, [])

  return (
    <section className="User">
      <header>
        <section className="userinfo">
          <section className="phone">{ userInfo?.mobile && formatPhone(userInfo?.mobile) }</section>
          {
            userInfo?.headImgUrl ?
            <section className="img-box">
              <img className="profile-box" src={userInfo?.headImgUrl} alt=""/>
            </section> :
            <section className="img-box">
              <section className="profile"></section>
            </section>
            
          }
        </section>
      </header>
      {/* <section className="activity">
        <dl>
          <dt>333</dt>
          <dd>优惠券</dd>
        </dl>
        <dl>
          <dt>333333</dt>
          <dd>会员积分</dd>
        </dl>
      </section> */}
      <ul className="entran">
        { entrance.map((item: Entrance) => {
          return (
            (!item.show.length || item.show.includes(currentPlatform)) ? <li key={item.title} className="entran-item" onClick={() => { userItemHandle(item) }}>
              <span className={`icon icon-${item.icon}`}></span>
              <span className={item.show.length ? 'sign-title' : 'title'}>{ item.title }</span>
              <span className="sign-status"
                style={{ display: item.show.length && item.icon === 'bank' ? '' : 'none'}} onClick={bankSignToggle}>{ isBankSign ? '已签约' : '未签约'}</span>
              <span className="sign-status"
                style={{ display: item.show.length && item.icon === 'digital' ? '' : 'none'}} onClick={digitalSignToggle}>{ isDigitalSign ? '已签约' : '未签约'}</span>
              <span className="arrow"></span>
            </li> : ''
            )
          })
        }
      </ul>
      <footer>
        <Tabbar />
      </footer>
    </section>
  )
}