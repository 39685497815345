import React, { useState, useEffect, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import http from '@/http/http';
import platform from '@/platform';
import { Toast, Dialog, SpinLoading } from 'antd-mobile';
import { orderType as orderTypeConstant, orderNormalStatus } from '@/constant/orderType';
import { scanType } from '@/constant/scanType';
import { useSelector, useDispatch } from 'react-redux';
import SkeleDetail from '@/components/SkeleDetail/SkeleDetail';
import { checkSign } from '@/utils/common';
import { getLocal, setLocal } from '@/utils/storage';
import './orderDetail.less';

// 路由跳转参数
type OrderRouterState = {
  orderSn: string
  [propsname: string]: any
}

// 订单详情
type OrderDetailInfo = {
  billingInfo?: any
  deductOrderInfo?: any
  deviceInfo?: any
  noteInfo?: any
  orderMark?: any
  paymentInfo?: any
  productInfos?: any
  refundOrderInfo?: any
  userInfo?: any
}

// 商品信息
type ProductInfo = {
  productName: string
  count: number
  productPrice: number
  [propsname: string]: any
}

// 去支付参数
type GoPayInfo = {
  orderSn: any
  isOrigin: any
}

export default function OrderDetail() {
  const { state } = useLocation();
  let [ orderSn ] = useState((state as OrderRouterState).orderSn); // 订单编号
  let [ orderDetail, setOrderDetail ] = useState({} as OrderDetailInfo); // 订单详情
  let [ payBtnDisabled, setPayBtn ] = useState(true);
  const currentPlatform = useSelector((state: any) => state.currentPlatform.platform.platform);
  // store中用户信息
  const globalUserInfo = useSelector((state: any) => {
    return state.userInfo.userInfo;
  })
  const userInfoStorage = getLocal('userInfo')
  let userInfo = Object.assign({}, globalUserInfo, userInfoStorage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * @desc 获取签约状态
  */
  const getUserSignStatus = async () => {
    const { code } = await checkSign(currentPlatform) as any;
    if(code === 1) { // 已经签约
      setPayBtn(false)
    } else {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      let path = '';
      switch(currentPlatform) {
        case 'alipay':
          path = '/protocol';
          break;
        case 'abc':
          path = '/abc/sign';
          break;
        case 'psbc':
          path = '/psbc/sign';
          break;
        case 'cmb':
          const { code: signCode, data: signUrl, message: signMsg } = await platform.cmb.cardSign(userInfo) as { code: number, data: string, message: string }
          if(signCode === 1 && signUrl) {
            window.location.href = signUrl;
          } else {
            Toast.show({
              icon: 'fail',
              content: signMsg || '授权失败',
            })
          }
          break;
        default:
          break;
      }
      path && navigate(path, { state: { active: 1, isOpen: false }})
    }
  }

  /**
   * @desc 获取订单详情
  */
  const getOrderDetail = () => {
    http.get({
      url: `/api/cuser/v1/order/details/${orderSn}`,
      data: {}
    }).then(res => {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      const { code, data } = res;
      if(code === 1) {
        getUserSignStatus()
        setOrderDetail(data)
      } else {
        setLocal({ key: 'token', value: '' })
        navigate('/userLogin', { replace: true })
      }
    })
  }

  /**
   * @desc 去支付
  */
  const payOrder = ({ orderSn, isOrigin }: GoPayInfo) => {
    if(orderDetail.noteInfo?.orderSourceId === scanType.WECHAT) {
      // 微信订单
      Dialog.confirm({
        title: '提示',
        content: <section>支付失败，请使用微信扫描货柜二维码进行支付，如有疑问，请联系客服或拨打电话 <a href="tel:029-86627864" className="tel">029-86627864</a></section>,
        closeOnMaskClick: false,
        confirmText: '联系客服',
        onConfirm: () =>   {
          const tel = document.querySelector('.tel') as any;
          tel.click()
        },
      })
      return;
    }
    if(payBtnDisabled) return;
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    http.get({
      url: `/api/cuser/v1/order/PayAgain/${orderSn}`,
    }).then(res => {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      if(res.code === 1) {
        Toast.show({
          icon: 'success',
          content: currentPlatform !== 'alipay' ? '支付成功' : res.data || res.msg || res.message || '支付成功',
          duration: 1000 * 1
        })
        setTimeout(() => {
          getOrderDetail()
        }, 1000 * 2)
      } else {
        Toast.show({
          icon: 'fail',
          content: res.msg || res.message || '支付失败',
        })
      }
    }).catch(() => {
      dispatch({
        type: 'SET_HIDE_LOADING'
      })
      Toast.show({
        icon: 'fail',
        content: '支付失败，请重试',
      })
    })
  }

  /**
   * @desc 复制orderSn
  */
  const copyOrderSn = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const ele = (e.currentTarget.previousSibling) as HTMLSpanElement;
    const range = document.createRange();
    (getSelection() as any).removeAllRanges();
    range.selectNode(ele);
    (getSelection() as any).addRange(range);
    document.execCommand('Copy');
    Toast.show({
      icon: 'success',
      content: '复制成功',
    })
  }

  /**
   * @desc 计算商品总数
   * @param {Array} productList 商品列表
  */
  const productCount = (productList: any[]): number => {
    let count = 0;
    productList && productList.length && productList.forEach(item => {
      count += item.count;
    })
    return count;
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if(!document.hidden) {
        getOrderDetail()
      }
    })
    dispatch({
      type: 'SET_SHOW_LOADING'
    })
    platform[currentPlatform as keyof typeof platform].setTitle('订单详情')
    dispatch({
      type: 'SET_NAVTITLE',
      value: '订单详情'
    })
    getOrderDetail()
  }, [])

  return (
    <Suspense fallback={<SpinLoading color='primary' />}>
      <section className="order-skele" style={{display: orderDetail.noteInfo ? 'none' : 'block'}}>
        <SkeleDetail />
      </section>
      <section className="orderDetails" style={{display: orderDetail.noteInfo ? 'block' : 'none'}}>
        <section className="order-status">
          <span style={{display: orderDetail.noteInfo?.orderType === orderTypeConstant.NORMAL ? 'block' : 'none'}} className="icon-status-normal"></span>
          <span style={{display: orderDetail.noteInfo?.orderType === orderTypeConstant.REFUND ? 'block' : 'none'}} className="icon-status-normal"></span>
          <span>{ orderDetail.noteInfo?.statusName }</span>
        </section>
        {/* 订单商品信息 */}
        <section className="order-product">
          <section className="location">
            <span className="address">{ orderDetail.deviceInfo?.deviceName }</span>
          </section>
          <ul style={{ display: orderDetail.productInfos?.length ? 'block' : 'none' }}>
            { orderDetail.productInfos?.length &&
              orderDetail.productInfos.map((item: ProductInfo, index: number) => {
              let style = {
                background: `url(${item.imageUrl}) no-repeat center center`,
                backgroundSize: 'cover'
              }
              return (
                <li className="product-box" key={index}>
                  <section className="product-info">
                    <section style={style} className="product-image"></section>
                    <dl>
                      <dt>
                        <span>{ item.productName }</span>
                        <span className="count">x{ item.count }</span>
                      </dt>
                      <dd>
                        <span className="payment">￥{ item.origin_Amount || 0 }</span>
                        <span className="real">￥{ item.realAmount || 0 }</span>
                      </dd>
                    </dl>
                  </section>
                </li>
              )
            }) }
          </ul>
          <section className="product-info-amount">
            <span className="common-total">共{ productCount(orderDetail.productInfos) || 0 }件商品</span>
            <span className="common-total">总价￥{ orderDetail.paymentInfo?.originAmount || 0 }</span>
            <span className="special">实付￥<span>{ orderDetail.paymentInfo?.realAmount || 0 }</span></span>
          </section>
          <header>订单信息</header>
          <section className="info-item">
            <span className="name">支付方式: </span>
            <span className="content">{ orderDetail.noteInfo?.paymentTypeName }</span>
          </section>
          <section className="info-item">
            <span className="name">下单地点: </span>
            <span className="content">{ orderDetail.deviceInfo?.deviceAddress }</span>
          </section>
          <section className="info-item">
            <span className="name">下单时间: </span>
            <span className="content">{ orderDetail.noteInfo?.createdTime }</span>
          </section>
          {
            orderDetail.noteInfo?.paymentTime ?
            <section className="info-item">
              <span className="name">支付时间: </span>
              <span className="content">{ orderDetail.noteInfo?.paymentTime }</span>
            </section> : ''
          }
          <section className="info-item">
            <span className="name">订单编号: </span>
            <span className="content">{ orderDetail.noteInfo?.orderSn }</span>
            <span className="copy" onClick={(e) => copyOrderSn(e)}>复制</span>
          </section>
          <section className="info-item" style={{ display: orderDetail.paymentInfo?.onsaleAmount ? 'block' : 'none' }}>
            <span className="name">优惠金额: </span>
            <span className="content">￥{ orderDetail.paymentInfo?.onsaleAmount }</span>
          </section>
          {/* 去支付 */}
          <section
            style={{ display: (orderDetail.noteInfo?.orderType === orderTypeConstant.NORMAL
              && orderDetail.noteInfo?.orderStatus === orderNormalStatus.RECOGNIZED) ||
            (orderDetail.noteInfo?.orderType === orderTypeConstant.UNPAY
              && orderDetail.noteInfo?.orderStatus !== orderNormalStatus.COMPLATED)
            ? 'block' : 'none' }}
            className="pay-btn"
            onClick={() => payOrder({ orderSn: orderDetail.noteInfo?.orderSn, isOrigin: true })}>去支付
          </section>
        </section>
        {/* 退款商品 */}
        <section className="refund-info" style={{ display: orderDetail.refundOrderInfo?.productInfos?.length ? 'block' : 'none' }}>
          <header>退款商品</header>
          <ul>
            { orderDetail.refundOrderInfo?.productInfos?.length &&
              orderDetail.refundOrderInfo?.productInfos.map((item: ProductInfo, index: number) => {
              let style = {
                background: `url(${item.imageUrl}) no-repeat center center`,
                backgroundSize: 'cover'
              }
              return (
                <li className="product-box" key={index}>
                  <section className="product-info">
                    <section style={style} className="product-image"></section>
                    <dl>
                      <dt>
                        <span>{ item.productName }</span>
                        <span className="count">x{ item.count }</span>
                      </dt>
                      <dd>
                        <span className="payment">￥{ item.origin_Amount || 0 }</span>
                        <span className="real">￥{ item.realAmount || 0 }</span>
                      </dd>
                    </dl>
                  </section>
                </li>
              )
            }) }
          </ul>
          <section className="product-info-amount">
            <span className="common-total">共{ productCount(orderDetail.refundOrderInfo?.productInfos) || 0 }件商品</span>
            <span>退款金额￥{ orderDetail.refundOrderInfo?.paymentAmount }</span>
          </section>
          <header>退款订单信息</header>
          <section className="info-item">
            <span className="name">退款时间: </span>
            <span className="content">{ orderDetail.refundOrderInfo?.paymentTime }</span>
          </section>
          <section className="info-item">
            <span className="name">订单编号: </span>
            <span className="content">{ orderDetail.refundOrderInfo?.orderSn }</span>
          </section>
        </section>
        {/* 补扣商品 */}
        <section className="deduct-Info" style={{ display: orderDetail.deductOrderInfo ? 'block' : 'none' }}>
          <header>补扣商品</header>
          <ul>
            { orderDetail.deductOrderInfo?.productInfos?.length &&
              orderDetail.deductOrderInfo?.productInfos.map((item: ProductInfo, index: number) => {
              let style = {
                background: `url(${item.imageUrl}) no-repeat center center`,
                backgroundSize: 'cover'
              }
              return (
                <li className="product-box" key={index}>
                  <section className="product-info">
                    <section style={style} className="product-image"></section>
                    <dl>
                      <dt>
                        <span>{ item.productName }</span>
                        <span className="count">x{ item.count }</span>
                      </dt>
                      <dd>
                        <span className="payment">￥{ item.origin_Amount || 0 }</span>
                        <span className="real">￥{ item.realAmount || 0 }</span>
                      </dd>
                    </dl>
                  </section>
                </li>
              )
            }) }
          </ul>
          <section className="product-info-amount">
            <span className="common-total">共{ productCount(orderDetail.deductOrderInfo?.productInfos) || 0 }件商品</span>
            <span>补扣金额￥{ orderDetail.deductOrderInfo?.paymentAmount }</span>
          </section>
          <header>补扣订单信息</header>
          <section className="info-item">
            <span className="name">补扣状态: </span>
            <span className="content">{ orderDetail.deductOrderInfo?.orderStatus_FillBuckle_Name }</span>
          </section>
          <section className="info-item">
            <span className="name">订单编号: </span>
            <span className="content">{ orderDetail.deductOrderInfo?.orderSn }</span>
            <span className="copy" onClick={(e) => copyOrderSn(e)}>复制</span>
          </section>
          <section
            style={{ display: orderDetail.deductOrderInfo?.orderStatus_FillBuckle !== 1 ? 'block' : 'none' }}
            className="pay-btn"
            onClick={() => payOrder({ orderSn: orderDetail.deductOrderInfo?.orderSn, isOrigin: false })}>
            <span>去支付</span>
          </section>
        </section>
        <section className="tip-text">如果对此订单有疑问，请联系客服</section>
      </section>
    </Suspense>
  )
}