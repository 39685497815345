import {legacy_createStore as createStore} from 'redux'
import reducers from '@/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
  key: 'rixiaols',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [ 'globalDeviceId', 'activeTabKey' ],
  transforms: [
    encryptTransform({
      secretKey: 'rixiao-secret-key',
    })
  ]
};

const PersistReducer = persistReducer(persistConfig, reducers as any);
const store = createStore(PersistReducer);

export const persistor = persistStore(store);
export default store;