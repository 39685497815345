import { combineReducers } from 'redux';

import activeTabKey from '@/reducers/activeKey'; // 选中tab
import globalDeviceId from '@/reducers/deviceId';
import userInfo from '@/reducers/useInfo';
import currentPlatform from '@/reducers/platform';
import currentNavTitle from '@/reducers/navTitle';
import showLoading from '@/reducers/loading';
import globalSelectDoor from '@/reducers/selectDoor';
import globalDoorType from '@/reducers/doorType';
import globalToken from '@/reducers/token';

export default combineReducers({
  activeTabKey,
  globalDeviceId,
  userInfo,
  currentPlatform,
  currentNavTitle,
  showLoading,
  globalSelectDoor,
  globalDoorType,
  globalToken
})