import alipay from '@/platform/alipay';
import abc from '@/platform/abc';
import psbc from '@/platform/psbc';
import cmb from '@/platform/cmb';

const platform = {
  alipay,
  abc,
  psbc,
  cmb,
}

export default platform;